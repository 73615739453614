.container {
  position: relative;
  width: 100%;
}

.options {
  position: absolute;
  display: flex;
  top: 95%;
  flex-direction: column;
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px solid #c8ccdd;
  width: 100%;
  z-index: 1;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden;
  justify-content: flex-start !important;
}

.option {
  width: 100%;
  padding: 6px 12px;
  justify-content: flex-start !important;
  &:hover {
    background-color: #f0ecff;
  }
}
