@import '../../../components/global/vars.scss';

.container {
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 9px;
  background-color: #fff;

  @media screen and (max-width: $lg) {
    border: none;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.375rem;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);

  @media screen and (max-width: $lg) {
    padding: 0 1.25rem;
  }

  &__company {
    display: flex;
    gap: 0.625rem;

    @media screen and (max-width: $sm) {
      gap: 0.5rem;
    }

    &__name {
      font-weight: 500;
      color: $darkGrey;

      @media screen and (max-width: $sm) {
        & > h3 {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}

.button {
  padding: 0.4375rem 2.1875rem;
  box-shadow: 0 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  color: $white;
  background-color: $primary;
  border: 1px solid $primary;
  transition: 300ms ease-in-out;

  &.disabled {
    background-color: $lightGrey2;
    border: 1px solid #C8CCDD;
    pointer-events: none;
  }

  @media screen and (max-width: $lg) {
    display: none;
    visibility: hidden;
    padding: 0.875rem 2.1875rem;
  }
}

.bottom {
  min-height: 216px;
  padding: 1.5rem 1.375rem;

  @media screen and (max-width: $lg) {
    padding: 2rem 1.25rem 1.5rem 1.25rem;
  }

  @media screen and (max-width: $sm) {
    min-height: auto;
  }


  & .button {
    display: none;
    visibility: hidden;

    @media screen and (max-width: $lg) {
      margin-top: 1.25rem;
      display: block;
      visibility: visible;
    }
  }

  @media screen and (max-width: $md) {
    & .button {
      width: 150px;
    }
  }

  @media screen and (max-width: $sm) {
    & .button {
      width: 100%
    }
  }
}
