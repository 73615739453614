.ActionButtonsGroup{
    display: flex;
    height: max-content;
    .ActionButton{
        cursor:pointer;
        padding: 0px;
        width: 24px;
        height: 24px;
        background-color: $lightPurple;
        border: 2px solid $purple;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        margin-left: 8px;
        transition: all 0.25s;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
        @include md{
            width: 32px;
            height: 32px;
            margin-left: 16px;
        }
        &:hover{
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1); 
        }
    
        img{
            width:13px;
            @include md{
                width:16px;
            }
        }
    }
}
