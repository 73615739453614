@import "../../global/vars.scss";
@import "../../global/interactions.scss";

.centralButton {
  width: 222px;
  border-radius: 15px;
  white-space: nowrap;
}

.containerEmpty {
  min-height: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-top: 8px;
  @include lg {
    min-height: 40vh;
    padding-top: 0;
    align-items: center;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.wrapp {
  min-height: 44vh;
  width: 100%;
  @include lg {
    min-height: auto;
  }

  & .top {
    display: flex;
    justify-content: space-between;
  }
}

.folder {
  position: relative;
  display: flex;
  width: 200px;
  align-items: center;
  height: 133px;
  opacity: 0;
  animation: fadein 300ms ease-in-out forwards;
  animation-fill-mode: forwards;
  transform: scale(0);

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    z-index: 1;
  }
}

.menu {
  position: absolute;
  top: 4.5%;
  left: 4.5%;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.name {
  position: absolute;
  left: 20px;
  bottom: 35px;
  font-weight: 500;
  color: #fff;
  font-size: 1rem;
  text-transform: capitalize;
  z-index: 2;
}

.category {
  position: absolute;
  left: 20px;
  bottom: 12px;
  font-weight: 500;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  z-index: 2;
  transform: translateX(20%);

  &:before { // outline border
    content: '';
    position: absolute;
    top: 1.5px;
    left: -22%;
    width: 140%;
    height: 75%;
    border: 1px solid #fff;
    border-radius: 15px;
  }
}

.folder {
  cursor: pointer;

  &__background {
    transition: 300ms ease-in-out;

    &:hover {
      transform: scale(1.02) translateY(-10px);
    }
  }
}

.attachment {
  position: relative;
  display: flex;
  width: 200px;
  height: 133px;

  &.new {
    order: 1000000;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;

    & .plus {
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 15px;

      & .lineOne {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 100%;
        border-radius: 3px;
        background-color: #8360E6;
        transform: translate(-50%, -50%) rotate(90deg);
      }

      & .lineTwo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 100%;
        border-radius: 3px;
        background-color: #8360E6;
        transform: translate(-50%, -50%);
      }
    }

    & .text {
      position: absolute;
      width: 100%;


      & .title {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #8C6CED;
        margin-bottom: 10px;
      }

      & .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        max-width: 70%;
        margin: 0 auto;
        color: #C6C6C6;
      }
    }
  }

  &__dots {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20px;
    top: 10px;
    right: 0;
    gap: 2px;
    cursor: pointer;
    align-items: center;
    z-index: 1;

    & > span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  &__settings {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(30%);
    opacity: 0;
    padding: 6px 0;
    width: fit-content;
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    z-index: 10;
    transition: 300ms ease-in-out;
    pointer-events: none;
    overflow: hidden;

    &__opened {
      transform: translateX(100%);
      pointer-events: all;
      opacity: 1;
    }

    &__openedFolder {
      transform: translateX(-70%);
      pointer-events: all;
      opacity: 1;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 21.79px;
      padding: 10px 10px;
      border-bottom: 1px solid #e2e8f0;
      transition: 300ms ease-in-out;
      color: #000;
      cursor: pointer;

      & img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        object-position: center;
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    &__item:last-child {
      border-bottom: none;
    }
  }

  :hover {
    cursor: pointer;
  }

  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
    background-color: transparent;
    border: none;
    opacity: 0;
    z-index: 1;
  }

  .contentImg {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      padding-top: 66.6666666667%;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s linear;
    }

    &:hover {
      .contentImg:before {
        opacity: 1;
        pointer-events: all;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &.new {
    @include plusBoxHover;
    border-radius: 0.4375em;

    img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 23%;
      transform: translate(-50%, -50%);
    }
  }

  @include md {
    .closeIcon {
      opacity: 0;
    }
    &:hover {
      .closeIcon {
        opacity: 1;
      }
    }
  }
}

.closeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  background: rgba(55, 61, 89, 0.4);
  transition: opacity 0.1s linear;

  img {
    filter: brightness(5);
    width: 10px;
  }
}

.attachmentFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  border-radius: 0 0 6px 6px;

  & > p {
    font-size: 12px;
    color: #000;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.modalAttach {
  max-width: 882px;
  border-radius: 6px;
}

.createFolderModal {
  min-height: 400px;
}

.modalBody {
  overflow: hidden;
  position: relative;
  height: 464px;
  border-radius: 6px 6px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.modalTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: rgba(0, 0, 0, 0.36);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px 6px 0 0;
  padding: 15px 20px;

  &.folder {
    background-color: #fff;

    & .title {
      color: #373D59;
      font-size: 20px;
      font-weight: 600;
    }

    button {
      position: relative;

      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: none;
        filter: unset !important;
        width: unset;
        height: unset;
      }
    }
  }

  button {
    width: 14px;
    height: 14px;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      filter: brightness(4);
    }
  }
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  &__backBtn {
    display: flex;
    gap: 2px;
    margin-right: 20px;
    cursor: pointer;

    &:hover {
      & .breadcrumbs__backBtn__icon {
        transform: scale(1.1);
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      transform: translateY(0.5px);
      transition: 300ms ease-in-out;
    }

    &__name {
      color: #373D59;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
    }
  }

  & > a {
    text-decoration: none;
    color: unset;
  }


  &__link {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;

    &__active {
      font-weight: 400;
      background: $purpleGradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent
    }
  }

  &__separator {
    margin: 0 6px;
  }
}

.actions {
  display: flex;
  gap: 15px;

  @media screen and (max-width: $md) {
    position: absolute;
    right: 0;
    top: 12px;

    & .icon {
      width: 30px;
      height: 30px;
    }
  }

  & .action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;

    & .icon {
      width: 35px;
      height: 35px;
      margin: 0 auto;
      object-fit: contain;

      &.file {
        height: 30px;
      }
    }

    &__name {
      font-weight: 400;
      color: $purple;
    }
  }
}
