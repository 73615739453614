.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;

  & > img {
    width: 10vw;
    height: 10vw;
    min-width: 50px;
    min-height: 50px;
  }
}

.borderRadius {
  border-radius: 20px;
}

.show {
  transition: 1s;
  opacity: 1;
  visibility: visible;
}

.hide {
  transition: 1s;
  opacity: 0;
  visibility: hidden;
}
