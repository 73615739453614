@import "../vars.scss";

.button {
    box-sizing: border-box;
    width: 185px;
    height: 46px;
    border-radius: 12px;
    background: #a78df2;
    border: 1px solid #ffffff;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    &:hover {
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        background: #bfaaff;
    }
}

.transparentButton {
    width: max-content;
    background: transparent;
    padding: 0px 25px;
    height: max-content;
    cursor: pointer;
    border-radius: 16px;
    border: none;
    transition: all 0.25s;
    font-size: 1rem;
    color: $lightGrey;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    span {
        line-height: inherit;
    }
    img {
        filter: invert(0.5);
    }
}

.glossyButton {
    background: linear-gradient(180deg, #9b7efa 0%, #7e5ae1 100%);
    border-radius: 15px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    padding: 12px 15px;
    transition: all 0.3s linear;
    border: 2px solid;
    border-top-color: #9b7efa;
    border-right-color: #8c6ced;
    border-bottom-color: #7d5ae1;
    border-left-color: #9273f2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include md {
        font-size: 16px;
        line-height: 22px;
    }
    &:hover {
        background: transparent;
        color: #8867ea;
        border-color: 2px solid #8e6eef;
    }
}

.plusButton {
    background: linear-gradient(180deg, #9b7efa 0%, #7e5ae1 100%);
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    outline: none;
    margin-top: 45px;
    &::after,
    &::before {
        content: "";
        background: #fff;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &::before {
        width: 12px;
        height: 2px;
    }
    &::after {
        width: 2px;
        height: 12px;
    }
}

.solidButton {
    width: max-content;
    text-align: center;
    padding: 0px 25px;
    height: max-content;
    cursor: pointer;
    border-radius: 16px;
    border: none;
    transition: all 0.25s;
    border: 2px solid transparent;
    span {
        font-weight: 600;
        font-size: 1rem;
        line-height: 40px;
        color: inherit;
    }
    &:hover {
        background: $purple;
        color: $white;
    }
}
.backButton {
    display: flex;
    align-items: center;
    gap: 16px;
    line-height: 150%;
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    span {
        display: none;
        @include md {
            display: block;
            font-weight: 500;
            font-size: 16px;
            color: #373d59;
        }
    }
}

.textContainer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tiktokButton,
.facebookButton,
.googleButton {
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    padding: 12px 10px;
    font-weight: 700;
    font-size: 16px;
    img {
        margin-right: 9px;
    }
    &.active {
        padding: 0;
    }
}

.tiktokButton {
    background-color: black;
    color: $white;
    border: 1px solid black;
    transition: all 0.3s linear;
    &:hover {
        background: #fff;
        border-color: #c8ccdd;
        color: black;
    }
}

.facebookButton {
    background-color: $white;
    color: #5d606b;
    border: 1px solid #c8ccdd;
    transition: all 0.3s linear;

    &:hover {
        background: #fff;
        border-color: #c8ccdd;
        color: #2a32e5;
    }
}

.googleButton {
    background-color: $white;
    color: #5d606b;
    border: 1px solid #c8ccdd;
    transition: all 0.3s linear;
    &:hover {
        background: #fff;
        border-color: #c8ccdd;
        color: #2a32e5;
    }
}
