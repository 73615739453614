@import '../vars.scss';
.container {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 9px;
  p {
    margin: 0 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #5D606B;
  }
}

.header {
  padding: 16px;
  border-bottom: 1px solid #ededed;
  @include lg{
    padding: 22px 20px 16px;
  }
  span {
    font-weight: 600;
    font-size: 16px;
  }
}

.body {
  padding: 32px 17px 30px;
  @include md{
    padding: 32px 20px 30px;
  }
  @include lg{
    padding: 24px 20px 22px;
  }
}
