@import "../../global/vars.scss";

.body {
  width: 100%;
  background-color: $white;
  padding: 20px;
  height: 100%;
  padding-top: 33px;
  border-radius: 16px;

  .header {
    .headerCard {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  .asnwerContainer {
    display: flex;
    flex-direction: column;
  }
}

.fileZone {
  &:hover {
    cursor: pointer;
  }

  width: 100%;
  min-height: 80px;
  position: relative;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238360E6FF' stroke-width='2' stroke-dasharray='20' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: transparent;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.attachments {
  margin-bottom: 16px;
  display: flex;
  row-gap: 12px;
  column-gap: 12px;
  flex-wrap: wrap;

  .loadingContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .info {
      display: flex;
      justify-content: space-between;

      .fileInfo {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        & > img {
          margin-right: 12px;
        }

        & > span {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .empty {
      position: relative;
      width: 100%;
      height: 3px;
      border-radius: 16px;
      background-color: #c4c4c4;

      & > span {
        color: $purple;
        position: absolute;
        right: 0;
        top: -25px;
      }

      .loaded {
        height: 100%;
        background-color: $purple;
        border-radius: 16px;
      }
    }
  }

  .imgContainer {
    display: flex;
    align-items: center;

    &:hover {
      .deleteContainer {
        display: block;
      }
    }

    position: relative;
    border-radius: 15px;
    overflow: hidden;

    img {
      width: 138px;
      height: 127px;
      object-fit: cover;
      object-position: center;
      border-radius: 15px;
    }

    .deleteContainer {
      display: none;
      position: absolute;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      background: rgba(255, 255, 255, 0.8);
      top: 10px;
      right: 10px;

      img {
        position: absolute;
        height: 12px;
        width: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .description {
      display: flex;
      position: absolute;
      bottom: 0;
      height: 30px;
      width: 100%;
      background-color: #d6cbff;
      padding: 8px 12px;
      text-align: center;

      span {
        -webkit-box-orient: vertical;
        font-weight: 400;
        font-size: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.borderForum {
  background: #ffffff !important;
  border-radius: 10px !important;
  @include lg {
    border-radius: 15px !important;
  }

  .container {
    height: 100%;
    padding: 14px !important;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
    @include lg {
      border-radius: 15px !important;
      padding: 18px !important;
    }
  }
}

.top {
  display: flex;
  width: 100%;
  position: relative;
  gap: 16px;
  flex-direction: column;
  @include xs {
    flex-direction: row;
    height: 40px;
  }
}

.searchContainer {
  :global {
    .input-search {
      @include xs {
        border: none;
        background-color: transparent;
        &.open,
        &:focus {
          border: 1px solid #d6d9e5;
          background-color: #fff;
        }
      }

      @include xl {
        border: 1px solid #d6d9e5;
        background-color: #fff;
        flex: 1;
      }
    }
  }
}

.chatBtn {
  white-space: nowrap;
  border-radius: 15px !important;
  font-weight: 500;
  @include xl {
    width: 174px;
  }

  img {
    margin-right: 6px;
    filter: brightness(3);
  }

  &:hover {
    img {
      filter: none;
    }
  }
}

.postContainer {
  display: flex;
  flex-direction: column;
}

.modalWrapper {
  @include md {
    min-width: 456px;
  }
}

.modalTitleContainer {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include md {
    padding: 20px;
  }

  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $darkGrey;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 32px 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 32px 20px 20px;
  }

  button {
    @include md {
      align-self: flex-end;
      margin-top: 27px;
      width: 162px;
    }
  }

  :global {
    .formControls {
      @include md {
        margin-bottom: 16px;
      }
    }
  }
}

.modal {
  width: 100%;
  @include md {
    max-width: 456px !important;
  }
}

.textInput {
  font-weight: 300;
  font-size: 14px;
}

.select {
  width: 100%;

  :global {
    .b__indicators {
      svg {
        width: 14px;
        min-width: 14px;

        path {
          stroke: #130F26
        }
      }
    }
  }

}

.saveBtn {
  width: 100%;
  align-self: flex-end;
  margin-top: 30px;
  border-radius: 15px;
  @include md {
    width: 162px;
  }
}

.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red;
  padding-top: 8px;
}