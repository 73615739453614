@import '../../../global/vars.scss';
@import '../../../global/interactions.scss';
.task{
  &Border{
    background: linear-gradient(250deg, rgba(255, 255, 255, 0) 2.25%, #FFFFFF 38.75%, #FFFFFF 44.91%, #FFFFFF 49.73%, rgba(255, 255, 255, 0) 98.48%);
    padding: 2px;
    border-radius: 20px;
    width: 100%;
    @include sm{
      width: auto;
    }
  }
  &Container {
    background: #F5F3F8;
    border-radius: 20px;
    padding: 24px 16px 32px;
    width: 100%;
    flex-shrink: 0;
    height: max-content;
    @include sm{
      width: 340px;
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
  }
}

.body {
  display: flex;
  flex-direction: column-reverse;
}

.droppable{
  display: flex;
  flex-direction:column;
  margin-bottom: 0.1px;
}

.newCard {
  width: 100%;
  height: 76px;
  border-radius: 6px;
  position: relative;

  @include plusBoxHover;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
