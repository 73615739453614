@import "../../global/vars";

.addBtn{
  background: $primary;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  width: 122px;
  padding: 6px 10px;
  color:white;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    margin-left: 10px;
  }
  &:hover{
    background: $purple;
  }
  span{
    padding-left: 4px;
  }
}

.checkBox{
  position: sticky;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  @include md{
    position: relative;
    grid-area: 1 / 5 / 2 / 6;
  }
  span{
    margin: 0 !important;
  }
  
}