.welcomemessage{
    @include xl{
        width: calc(60% - 10px);
        min-width: calc(60% - 10px)
    }
    textarea.greeting{
        &[disabled]{
            text-decoration: none;
        }
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        width:100%;
        height: 100%;
        color: $darkGrey;
        caret-color: $purple;
        padding-bottom:8px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: #c8ccdd;
        text-underline-position: under;
        text-underline-offset: 4px;
        text-decoration-thickness: 2px;
        background: none;
        border:none;
        text-align: center;
        resize: none;
        outline: none;
        @include lg{
            font-size: 38px;
        }
    }
    .boxHeader{
        display: flex;
        justify-content: flex-end;
        padding: 14px 16px 3px;
        @include md{
            padding: 20px;
        }
    }
    .boxBody{
        padding: 0 20px 16px;
        @include md{
            padding: 0 20px 20px;
        }
    }
}
