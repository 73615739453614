@import "../vars.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 4px;
  width: 86px;

  :global{
    .clock {
      height: 103px !important;
      width: 86px !important;
      background: #F5F4F8;
      border: 2px solid #E9E9E9;
      border-radius: 11px;
    }
    .react-clock{
      height: 99px !important;
      width: 82px !important;
      background-image: url("./clock.svg");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      &::after{
        content: '';
        display: block;
        background: #3F3F3F;
        width: 5.65px;
        height: 5.65px;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .react-clock__hour-hand__body,.react-clock__minute-hand__body , .react-clock__mark__body{
      background-size: 95% 95%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .react-clock__second-hand__body{
      background-color: #A5A5A5;
      width: 1px !important;
      border-radius: 20px;
    }
    .react-clock__hour-hand__body{
      background-color: #FFCF70;
      width: 2.5px !important;
      border-radius: 20px;
    }
    .react-clock__minute-hand__body{
      background-color: #8C6CED;
      width: 2px !important;
      border-radius: 20px;
    }
    
  }
}

.description {
    text-align: center;
    @include md{
      margin-top: 2px;
    }
  .time {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 4px;
    @include md{
      font-size: 12px;
      line-height: 16px;
    }
  }
  .city {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
    word-break: break-all;
    @include md{
      word-break: normal;
    }
  }
}
