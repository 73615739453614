@import "../../global/vars.scss";
@import "../../global/interactions.scss";

.colorModal {
  max-width: 658px;
}

.modalTitleContainer {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include md {
    padding: 24px 37px 25px;
  }
  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $darkGrey;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 32px 16px 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 32px 36px 27px;
  }
}

.colorsContainer {
  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    margin-bottom: 8px;
    @include md {
      margin-bottom: 19px;
    }
  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.sphere {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.add {
    border: 1px solid #acacac;
    box-sizing: border-box;
  }
}

.modalButton {
  margin-top: 47px;
  align-self: flex-end;
  width: 100%;
  @include md {
    width: 222px;
  }
}

.colorPicker {
  & div:nth-child(4) {
    display: none !important;
    visibility: hidden;
  }
}
