.navlink{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $lightGrey;
    letter-spacing: -0.4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    flex-direction: column;

    @include md{
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 20px;
        flex-direction: row;
    }

    &:hover{
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        font-weight: 700;
        color: $darkGrey;
        .icon{
            display: none;
        }
           .icon_active{
               display: inline-block;
           }
    }

    &.active{
        font-weight: 700;
        color: $darkGrey;
    }

    &__icon{

        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-bottom: 2px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        @include md{
            margin-bottom: 0;
            margin-right: 10px;
        }
    }
    .icon_active{
        display: none;
    }


}

.navlink.active  {
 .icon{
     display: none;
 }
    .icon_active{
        display: inline-block;
    }
}