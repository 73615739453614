@import "../global/vars.scss";

.wrapper {
  .body {
    padding: 0;
    background: none;
    width: calc(100% + 24px);
    margin: 0 -12px;
    @include md {
      margin: 0;
      width: 100%;
      background: #fff;
      padding-bottom: 20px;
    }
    @include lg {
      min-height: 75vh;
      padding-bottom: 74px;
    }
  }

  .toggle {
    margin: 0 !important;
    margin-right: 12px !important;

    input[type="checkbox"] + label:after {
      margin-left: 7px;
    }

    input[type="checkbox"] + label:before {
      width: calc(50px - 2 * 2px);
      height: calc(28px - 2 * 2px);
    }

    input[type="checkbox"]:checked + label:after {
      margin-left: calc(100% - 15px - 5px);
    }
  }
}

.header {
  background: #fff;
  padding: 0 20px 20px;
  @include md {
    background: none;
    padding: 20px;
  }
  @include lg {
    padding: 32px 34px 0;
  }

  & > div {
    display: flex;
    border-bottom: 1px solid rgba(200, 204, 221, 0.51);
    justify-content: space-between;
    @include lg {
      justify-content: flex-start;
    }
  }
}

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31%;
  padding: 0 10px 8px;
  flex-direction: column;
  @include lg {
    flex-direction: row;
    width: auto;
    padding: 0px 20px 10px;
    margin-right: 22px;
  }

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #8c6ced80;
    margin-bottom: -2px;

    span {
      color: #8c6ced80;
    }
  }

  &.active {
    border-bottom: 2px solid #8c6ced;
    margin-bottom: -2px;

    span {
      color: #8c6ced;
    }
  }

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #c8ccdd;
    @include lg {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
  }

  img {
    margin-bottom: 3px;
    @include lg {
      margin-right: 12px;
    }
  }
}

.title {
  margin: 0 -12px;
  width: calc(100% + 24px);
  background: #fff;
  padding: 20px 20px 32px;
  @include md {
    background: none;
    margin: 0 0 24px;
    width: 100%;
    padding: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 0;
    @include lg {
      font-size: 32px;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: -18px;
  @include md {
    margin-bottom: 0;
  }
  @include lg {
    padding: 10px 32px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    &.contentProfile {
      grid-template-columns: 42% calc(58% - 20px);

      .saveBtn {
        @include lg {
          width: 148px;
        }
      }

      .bigCard {
        width: 100%;
        @include lg {
          grid-area: 1 / 2 / 3 / 3;
        }
      }
    }
    &.contentNotifications {
      grid-template-columns: 1fr 393px;
    }
  }
}

.card {
  flex: 1;
  border: none !important;
  margin: 24px 12px 0;
  background: #fff;
  border-radius: 8px !important;
  @include md {
    margin: 24px 0 0;
  }
  @include lg {
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 9px !important;
  }

  .formControls {
    margin-bottom: 10px;
    align-items: flex-start;
    @include lg {
      margin-bottom: 20px;
    }
  }

  .toggleLabel {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.contentNotifications {
  @include lg {
    .formControls {
      margin-bottom: 20px;
    }
  }
}

.contentProfile {
  .card {
    border-radius: 8px 8px 0 0 !important;
    @include lg {
      border-radius: 9px !important;
    }
  }
}

.select {
  & > div {
    width: 100%;
  }
}

.profileCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 18px;

  span {
    font-weight: 400;
    font-size: 14px;
    color: #a5a5a5;
    text-align: center;
  }

  .profileImg {
    position: relative;
    z-index: 8;
    border-radius: 50%;
    width: 98px;
    height: 98px;
    overflow: hidden;
    background: #c8ccdd;

    img {
      position: relative;
      z-index: 8;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .mobileProfileCard {
    width: 136px;
    height: 136px;
    position: relative;

    .profileImg {
      width: 100%;
      height: 100%;
    }

    .photoBtn {
      width: 47px;
      min-width: 47px;
      height: 47px;
      min-height: 47px;
      background: linear-gradient(180deg, #9b7efa 0%, #7e5ae1 100%);
      border: 6px solid #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -13px;
      right: 5px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.halfInput {
  width: 50%;
}

.compactInput {
  display: flex;
  column-gap: 16px;
  flex-direction: column;
  @include xs {
    flex-direction: row;
  }
}

.smallCard {
  height: max-content;
}

.bigCardWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .btnWrapper {
    position: relative;
    width: fit-content;

    & > div > img { // preloader img
      width: 4vw;
      height: 4vw;
    }
  }
}

.deactivateBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  background: #f85c56;
  border-radius: 15px;
  padding: 14px;
  width: 100%;
  @include lg {
    background: none;
    justify-content: flex-start;
    padding: 0;
  }

  img {
    filter: brightness(3);
    @include lg {
      filter: none;
    }
  }

  span {
    margin-left: 6px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    @include lg {
      color: #f85c56;
    }
  }
}

.saveBtn {
  width: 100%;
  @include lg {
    width: auto;
  }
}

.profileLeftWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  background: #fff;
  padding-bottom: 30px;
  padding-top: 12px;
  @include lg {
    padding: 0;
    grid-area: 1 / 1 / 2 / 2;
  }
}

.socialBtnGroup {
  background: #fff;
  border-radius: 0 0 8px 8px;
  padding: 44px 17px 10px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 34px);
    height: 1px;
    background: #c8ccdd;
    top: 0;
    @include md {
      width: calc(100% - 40px);
    }
  }

  &:after {
    content: "or";
    display: block;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 30px;
    background: #fff;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: #e6e6e6;
  }

  margin: 0 12px;
  @include md {
    margin: 0;
    padding: 44px 20px 10px;
  }
  @include lg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-area: 2 / 1 / 3 / 2;
    padding: 0;
    &::after,
    &::before {
      display: none;
    }
  }
  @include xxl {
    padding: 0 17%;
  }
}

.socialBtn {
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  position: relative;
  margin-bottom: 11px;
  @include lg {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.socialBtnActive {
    padding: 0;
    justify-content: flex-start;
    background: none;
    margin-bottom: 17px;
    border: none;
    @include lg {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      margin-left: 12px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9' stroke='%23373D59' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M9 1L1 9' stroke='%23373D59' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      @include lg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
    }
  }
}

.notificationSpan {
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 20px;
}

.checkBox {
  font-weight: 400;
  font-size: 14px;
  color: black;

  span {
    height: 20px !important;
    width: 20px !important;
  }
}

.notificationBtn {
  margin-top: 33px;
  width: 100%;
  @include lg {
    width: 233px;
  }
}

.compactInputPhone {
  background: #000;
}

.deleteBtnModal {
  width: 105px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #f85c56;

    img {
      filter: none;
    }
  }
}

.modalDelete {
  max-width: 576px !important;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.modalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 20px;
  row-gap: 20px;
  @include lg {
    padding: 0 16px 54px;
    row-gap: 19px;
  }

  & > img {
    width: 45px;
  }

  & > p {
    margin: 0;
    max-width: 350px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    @include lg {
      font-size: 20px;
    }

    b {
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
    }
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    @include lg {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.btnContainer {
  display: flex;
  column-gap: 26px;
}

.noBtnModal {
  width: 105px;
  outline: none;
  background-color: #9b7efa;
  border: 1px solid #9b7efa;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #9b7efa;

    img {
      filter: none;
    }
  }
}

.logoutContainer {
  &:hover {
    cursor: pointer;

    span {
      color: $error;
    }
  }

  padding: 0 0 10px 20px;
  margin-left: auto;
  display: flex;
  align-items: center;

  img {
    margin-right: 7px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: #c8ccdd;
  }
}

.logOutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 12px 0;
  background-color: $white;
  padding: 0;
  border-radius: 8px;
  width: calc(100% - 24px);

  & > div {
    display: flex;
    column-gap: 7px;

    span {
      font-weight: 500;
      font-size: 16px;
      color: #c8ccdd;
    }
  }

  &:hover {
    img {
      filter: brightness(4);
    }

    & > div {
      span {
        color: #fff;
      }
    }
  }
}

.configuration {
  grid-row-gap: 20px;
  padding-top: 34px;

  @media screen and (max-width: $md) {
    margin: 0 0.75rem;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  & .logOutButton {
    margin-top: 0;
  }
}

.signIn {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1.5px);
  z-index: 1;

  & button {
    width: fit-content;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 13.5px;
  }
}
