@mixin cardBoxShadow{
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
}

@mixin disableSelection {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

@mixin transitionOptions {
  transition: all 0.16s linear;
  -webkit-transition: all 0.16s linear;
  -moz-transition: all 0.16s linear;
  -ms-transition: all 0.16s linear;
  -o-transition: all 0.16s linear;
}

@mixin cardHover {
  @include transitionOptions;
  box-shadow: 0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.16);
}

@mixin plusBoxHover {
  @include transitionOptions;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238360E6FF' stroke-width='2' stroke-dasharray='20' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: transparent;
  &:hover{
    cursor: pointer;
    background-color: #9a7cf921;
  }
}

@mixin scaleHover {
  @include transitionOptions;
  &:hover{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
}

@mixin animateBack {
  @include transitionOptions;
  &:hover{
    transform: translateX(-6px);
    -webkit-transform: translateX(-6px);
    -moz-transform: translateX(-6px);
    -ms-transform: translateX(-6px);
    -o-transform: translateX(-6px);
  }
}
