@import "../../global/vars";

.wrap {
  padding: 10px 20px;
  @include md {
    padding: 9px 14px;
  }
  :global {
    .id {
      @include md {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
      }
    }
  }
  &.adminWrap{
    padding: 10px 13px 10px;
    @include md {
      padding: 11px 26px 11px 17px;
    }
  }
}

.rightArrowBtn{
  background: none;
  border: none;
  margin: 0;
  width: 15px;
  height: 30px;
  padding: 0 7px;
  &:hover{
    background: none;
  }
}

.name {
  &:hover {
    color: $purple;
    cursor: pointer;
  }
}

.miniEditBtn {
  border: none;
  outline: none;
  background-color: transparent;
}

.toggle {
  display: flex;
  justify-content: flex-start;
}

.checkBoxWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.viewBtn {
  margin-top: 12px;
  padding-right: 16px;
  padding-left: 16px;
  justify-content: center;
}

.leftProfile {
  padding: 24px 20px 19px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include md{
    padding: 30px 20px 0;
    width: 232px;
  }
  & > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    @include md{
      width: 112px;
      height: 112px;
      border-radius: 12px;
    }
  }
}

.rightProfile {
  width: 100%;
  padding: 20px;
  display: flex;
  column-gap: 20px;
  row-gap: 17px;
  border-top: 1px solid #E2E8F0;
  flex-wrap: wrap;
  justify-content: space-between;
  @include md{
    justify-content: flex-start;
    border-top: none;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    padding: 28px 30px 0 59px;
    row-gap: 23px;
    flex: 1;
    height: max-content;
  }
}
.bio{
  margin-top: 15px;
  @include md{
    margin-top: 4px;
  }
}
.bigViewInput {
  width: 100% !important;
}
.socialBtn {
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  position: relative;
  padding: 10px;
  @include md{
    width: 250px;
  }
  &.socialBtnActive{
    padding: 0;
    justify-content: flex-start;
    background: none;
    margin-bottom: 17px;
    border: none;
    @include lg{
      margin-bottom: 24px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &:after{
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      margin-left: 12px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9' stroke='%23373D59' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M9 1L1 9' stroke='%23373D59' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      @include lg{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
    }
  }
}
.viewInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include md{
    width: calc(40% - 10px);
  }
  &.col{
    width: calc(50% - 10px);
    @include md{
      width: calc(40% - 10px);
    }
  }
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #a5a5a5;
    margin-bottom: 3px;
  }
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: #5d606b;
    margin: 0;
  }
}

.modalBody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include md{
    flex-direction: row;
  }
  &View {
    padding: 0 !important;
    column-gap: 0px;
  }
  &Edit{
    padding: 0;
    @include md{
      padding: 17px 20px 53px 32px;
      gap: 20px
    }
  }
}

.profile {
  text-align: left;
  padding-right: 10px;
  display: flex;
  max-width: 100%;
  overflow: auto;
  min-width: 150px;
  align-items: center;
  &::-webkit-scrollbar {
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    height: 2px;
    cursor: pointer;
  }
  
  .profileName, .profileImg{
    padding: 0;
    overflow: hidden;
    background: none;
    border: none;
  }
  .profileName{
    font-size: 14px;
    line-height: 19px;
    border-radius: 0;
    height: auto;
    width: auto;
    color: #373D59;
    font-weight: 400;
    overflow-x: auto;
    white-space: nowrap;
    &:not(span){
      &:hover{
        color: #8C6CED;
      }
    }
    *{
      text-align: left;
    }
    @include md{
      font-size: 16px;
      line-height: 22px;
    }
  }
  .profileImg{
    margin: 0 10px 0 0;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    @include lg {
      margin: 0 17px 0 0;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
    }
    img {
      width: 30px;
      height: 30px;
      @include lg{
        width: 40px;
        height: 40px;
      }
    }
  }
  
}

.deleteBtn, .editBtn {
  outline: none;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 20px;
  &:hover{
    background: none;
  }
  @include md{
    height: 36px;
    margin-top: 15px;
    height: 36px;
    padding-right: 16px;
    padding-left: 16px;
    width: 122px;
    justify-content: center;
    position: static;
    &:hover {
      img {
        filter: brightness(5);
      }
    }
  }
  span{
    display: none;
    @include md{
      display: block;
    }
  }
  img {
    @include md{
      margin-right: 18px;
    }
  }
  
}
.editBtn {
  color: $purple;
  right: 56px;
  @include md{
    border: 1px solid $purple;
    &:hover{
      background: $purple;
    }
  }
}

.deleteBtn {
  color: #f85c56;
  right: 20px;
  @include md{
    border: 1px solid #f85c56;
    &:hover{
      background: #f85c56;
    }
  }
}

.socialGroup {
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  width: 100%;
  @include md{
    margin-bottom: 46px;
  }
  & > div {
    display: flex;
    & > span {
      font-weight: 600;
      font-size: 16px;
    }
    & > img {
      margin-right: 15px;
    }
  }
}

.editBtnWrapper {
  display: flex;
  max-width: 158px;
  justify-content: space-between;
  align-items: center;
  .editBtn{
    @include md{
     margin-top: 0;
     justify-content: center;
     width: 122px;
    } 
   }
}

.bigCard {
  width: 100%;
  height: 100%;
  flex: 1 !important;
  @include md{
    max-width: 100%!important;
    width: auto!important;
  }
}

.bigCardWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid #E2E8F0;
  padding: 10px 16px 20px;
  @include md{
    border-top: none;
    padding: 2px 8px 0;
  }
  .bio{
    margin-top: 0;
    margin-bottom: 20px;
    @include md{
      display: none;
    }
  }
}

.formControls {
  margin-bottom: 18px;
  align-items: flex-start;
  @include lg {
    margin-bottom: 20px;
  }
}

.halfInput {
  width: 50%;
}

.select {
  & > div {
    width: 100%;
  }
}

.saveBtn {
  @include md {
    width: 148px;
    margin-top: 12px;
  }
}

.compactInputPhone {
  background: #000;
}

.compactInput {
  display: flex;
  column-gap: 16px;
  flex-direction: column;
  @include xs {
    flex-direction: row;
  }
}

.card {
  border: none;
  padding: 24px 20px 17px;
  @include md {
    height: max-content;
    border-radius: 9px !important;
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
    width: 255px;
    padding: 0;
    &>div{
      &:first-child{
        padding: 18px 20px 20px;
      }
      &:last-child{
        padding: 18px 11px 30px;
      }
    }
  }
  .formControls {
    margin-bottom: 10px;
    align-items: flex-start;
    @include lg {
      margin-bottom: 20px;
    }
  }
  .toggleLabel {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.profileCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 18px;
  span {
    font-weight: 400;
    font-size: 14px;
    color: #a5a5a5;
    text-align: center;
  }
  .profileImg {
    border-radius: 15%;
    width: 95px;
    height: 95px;
    overflow: hidden;
    background: #c8ccdd;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

.modalTitleContainer {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include md {
    padding: 28px 20px 24px 60px;
  }
  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .backBtn{
    width: 30px;
    height: 30px;
    border: 1px solid #BFBFBF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    margin-right: 7px;
    span{
      display: block;
    }
    @include md{
      width: 43px;
      height: 43px;
      margin-right: 16px;
    }
    img{
      width: 16px;
      height: 12px;
      @include md{
        width: 17px;
        height: 16px;
      }
    }
  }
  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $darkGrey;
      display: flex;
      align-items: center;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalWrapper {
  width: 100%;
  max-width: 852px;
}

.userModal{
  max-width: 852px!important;
  &.edit{
    max-width: 878px!important;
    .modalWrapper{
      max-width: 878px;
    }
    .modalTitleContainer{
      padding: 20px 30px 16px 32px;
    }
  }
  .modalTitleContainer{
    p.modalTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.mobileProfileCard{
  width: 100px;
  height: 100px;
  position: relative;
  .profileImg{
    width: 100%;
    height: 100%;
  }
  .photoBtn{
    width: 47px;
    min-width: 47px;
    height: 47px;
    min-height: 47px;
    background: linear-gradient(180deg, #9B7EFA 0%, #7E5AE1 100%);
    border: 6px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -12px;
    right: -14px;
    img{
      width: 20px;
      height: 20px;
    }
  }
}