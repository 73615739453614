@import "../global/vars.scss";

.container {
  justify-content: center;
  align-items: center;
}

.notFoundContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;

  img {
    width: 175px;
    @include md {
      width: initial;
    }
  }

  .verbalContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    p {
      line-height: 36px;
      font-weight: 700;
      font-size: 24px;
      margin: 0;
      padding: 0;
    }

    span {
      font-weight: 300;
      font-size: 16px;
    }
  }
}

.btn {
  width: max-content;
  padding-left: 40px;
  padding-right: 40px;
}