@import "../../../global/vars.scss";

.fileTree {
  & .title {
    font-size: 0.875rem;
    font-weight: 300;
    color: $darkGrey;
    margin-top: 1.375rem;
  }
}

.currentLocation {
  display: flex;
  gap: 0.75rem;

  &__label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 300;
    color: $darkGrey;
  }

  &__group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: $darkGrey;
    font-weight: 400;
  }
}

.folders {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
  max-height: 9.375rem;
  margin-top: 1.125rem;
  overflow-y: auto;
  overflow-x: hidden;

  &__folder {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &__name {
      color: $darkGrey;
      font-weight: 400;
      font-size: 0.875rem;
      flex-grow: 1;
    }

    &__folderIcon, &__arrowIcon {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      color: $primary;

      & .folders__folder__arrowIcon {
        transform: translateX(-0.25rem) scale(1.05);
      }
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  &__levelUp {
    margin-right: 0.75rem;
    cursor: pointer;
  }

  &__folder {
    cursor: pointer;

    &__name {
      font-size: 0.875rem;
      font-weight: 300;
      color: $darkGrey;

      &__active {
        color: $primary !important;
      }
    }
  }

  &__divider {
    margin: 0 0.5rem;
    color: $darkGrey;
  }

  &__backBtn {
    display: flex;
    gap: 0.125rem;
    margin-right: 1.25rem;
    cursor: pointer;

    &:hover {
      & .breadcrumbs__backBtn__icon {
        transform: scale(1.1);
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      transform: translateY(0.03125rem);
      transition: 300ms ease-in-out;
    }

    &__name {
      font-size: 0.875rem;
      font-weight: 300;
      color: $darkGrey;
    }
  }
}

.submitBtn {
  background: linear-gradient(180deg, #9B7EFA 0%, #7E5AE1 100%);
  transition: background 300ms ease-in-out;
  display: block;
  margin: 1.375rem 0 0 auto;

  &__disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &:hover {
    background: linear-gradient(150deg, #9B7EFA 0%, #7E5AE1 100%);
  }
}
