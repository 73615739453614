@import "../vars.scss";
.container {
  font-size: 12px;
  color: #7E5AE1;
  font-weight: 400;
  line-height: 18px;
  word-spacing: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
  @include lg{
    font-size: 16px;
    line-height: 19px;
    gap: 7px;
  }
  &>span{
    &:hover{
      cursor: pointer;
    }
  }
}

.active {
  color: #373D59;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  max-width: 130px;
  display: block;
}

span.slash:last-child {
  color: #7E5AE1;
}
