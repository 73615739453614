@import '../../global/vars.scss';
@import '../../global/interactions.scss';

.centralButton {
  width: 210px;
  border-radius: 15px;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  width: 100%;
  @include md2 {
    grid-gap: 40px 20px;
    grid-template-columns: repeat(auto-fill, minmax(237px, 1fr));
  }
  @include lg {
    padding: 0 20px;
  }
}

.containerEmpty {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8px;
  @include xl {
    flex: 1;
    padding-top: 0;
    align-items: center;
  }
}

.noteBtnMobile {
  margin-bottom: 30px;
  width: 100%;
}

.note {
  &:hover {
    cursor: pointer;
  }

  background-color: #faf9fe;
  position: relative;
  border-radius: 4px;
  padding: 19px;

  &.new {
    height: 67px;
    width: auto;
    border: none;
    border-radius: 6px;

    @include plusBoxHover;

    img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @include md2 {
      height: 237px;
      &:hover {
        height: 237px;
      }
    }
  }

  .noteWrapp {
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  p {
    margin: 0;

    &.title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 13px;
      cursor: pointer;

      &:hover {
        color: #9A7CF9
      }
    }

    &.content {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      word-break: break-all;
    }
  }

  .btnGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 5px;
    @include md2 {
      position: absolute;
      z-index: 2;
      overflow: hidden;
      margin-top: 0;
      padding-right: 20px;
      top: calc(100% - 20px);
      left: 0;
      right: 0;
      height: 21px;
      width: 100%;
      background-color: #faf9fe;
      border-radius: 0 0 4px 4px;
      border-top: none;
      transition: height 0.2s ease-out, opacity 0.3s;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      width: 20px;
      height: 20px;
      border-radius: 0;

      &:hover {
        background: none;
      }

      @include md2 {
        opacity: 0;
        transition: opacity 0.2s linear 0s;
      }
    }
  }

  @include md2 {
    height: 237px;
    &:not(.new) {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        box-shadow: 0 0 0 1px transparent;
        z-index: 1;
        opacity: 0;
        transition: opacity .1s linear, box-shadow .2s linear .1s;
      }

      &:hover {
        &:after {
          height: calc(100% + 27px);
          box-shadow: 0 0 0 1px #c4b3f7;
          opacity: 1;
        }

        .btnGroup {
          height: 47px;
          opacity: 1;

          button {
            opacity: 1;
          }
        }

        p.content {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.modalDelete {
  max-width: 576px !important;
}

.deleteBtnModal {
  width: 120px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #f85c56;

    img {
      filter: none;
    }
  }
}

.deleteModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.deleteModalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.deleteModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 40px;
  @include lg {
    padding: 0 25px 42px;
  }

  & > img {
    width: 45px;
    display: block;
    margin-bottom: 18px;
    @include lg {
      margin-bottom: 22px;
    }
  }

  & > p {
    margin: 0;
    max-width: 250px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
    @include lg {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 22px;
    }
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
}

.btnContainer {
  display: flex;
  column-gap: 26px;
}

.noBtnModal {
  width: 120px;
  outline: none;
  background-color: #8c6ced;
  border: 1px solid #8c6ced;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #8c6ced;

    img {
      filter: none;
    }
  }
}
