@import '../global/vars.scss';

a {
  cursor: pointer;
}

.auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 25px;
  overflow: hidden;
  min-height: 100vh;
  background: #fff;
  @include md {
    flex-direction: row;
    padding-bottom: 0;
  }
}

.authWhite {
  background-color: white;
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
  @include md {
    width: 51%;
  }

  &Register {
    @include lg {
      padding: 0 5rem 0 0;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 500px;
        height: 200%;
        background: #ffffff;
        transform: skewX(-9deg);
        top: -50%;
        left: -82px;
        z-index: -1;
        @media screen and (min-height: 1040px) {
          left: -110px;
          transform: skewX(-6deg);
        }
      }
    }
  }

  &Login {
    @include lg {
      padding: 0 0 0 30px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 500px;
        height: 200%;
        background: #ffffff;
        transform: skewX(9deg);
        top: -50%;
        right: -82px;
        z-index: -1;
        @media screen and (min-height: 1040px) {
          right: -110px;
          transform: skewX(6deg);
        }
      }
    }
  }
}

.authPurpleSplit {
  display: none;
  @include md {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: linear-gradient(180deg, #9B7EFA 0%, #7E5AE1 100%);
    width: 49%;
    padding: 0 20px;
  }
  @include xl {
    padding: 0;

  }

  &Register {
    @include lg {
      justify-content: flex-start;
      padding: 0 0 0 4%;
    }
    @include xl {
      padding: 0 0 0 9.8%;
      @media screen and (min-height: 1040px) {
        padding: 0 0 0 4%;
      }
    }
    @include xxl {
      padding: 0 0 0 13%;
    }
  }

  &Login {
    @include lg {
      justify-content: flex-end;
      padding: 0 4% 0 0;
    }
    @include xl {
      padding: 0 9.8% 0 0;
      @media screen and (min-height: 1040px) {
        padding: 0 4% 0 0;
      }
    }
    @include xxl {
      padding: 0 13% 0 0;
    }
  }
}

.backLink {
  position: relative;
  z-index: 1;

  &Block {
    position: relative;
    width: 100%;
    height: 105px;
    margin-bottom: 10px;
    padding: 48px 24px 36px;
    @include md {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: 620px;
      height: 330px;
      border-radius: 50%;
      background: linear-gradient(151.02deg, #9B7EFA 41.13%, #7E5AE1 82.18%);
      transform: rotate(-6.71deg);
      position: absolute;
      bottom: 3px;
      left: -175px;
    }
  }
}

.logo {
  display: none;
  @include md {
    width: 160px;
    margin-bottom: 32px;
    display: block;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.login {
  &Wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 480px;
    padding: 40px 0;
    width: 100%;
    @include md {
      text-align: center;
      max-width: 367px;
      align-items: center;
    }
  }

  &Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 45px;
    line-height: 37px;
    width: 100%;
    color: #C8CCDD;
    font-weight: 400;

    @media screen and (max-width: $md) {
      align-items: flex-start;
      margin-bottom: 32px;
    }

    & .description {
      text-align: center;
      line-height: 1.3621rem;
      font-size: 14px;
      font-weight: 400;

      @media screen and (max-width: $md) {
        text-align: left;
        margin-top: 8px;
      }
    }

    & > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #C8CCDD;
    }

    a {
      text-decoration: none;
      color: #C8CCDD;
    }

    @include md {
      justify-content: center;
      margin-bottom: 36px;
    }

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #373D59;
      margin: 0;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #373D59;


        @media screen and (max-width: $md) {
          margin: 0 4px;
          color: #C8CCDD
        }
      }

      @include md {
        font-weight: 700;
        text-transform: capitalize;
        font-size: 20px;

        span {
          font-weight: 700;
          text-transform: capitalize;
          font-size: 20px;
        }
      }
    }
  }

  &Subtitle {
    font-weight: 100;
    font-size: 14px;
    line-height: 19px;
    color: #C8CCDD;
    margin-bottom: 32px;
    max-width: 292px;
    @include md {
      padding: 0 20px;
      margin-bottom: 36px;
      text-align: center;
      max-width: 100%;
    }
  }
}

.separatorOR {
  position: relative;
  width: 100%;
  height: 1px;
  background: #E2E8F0;
  margin: 33px 0  31px 0;

  @include md {
    margin: 44px 0;
  }

  &:after {
    content: 'or';
    text-align: center;
    display: block;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #E6E6E6;
    font-weight: 100;
    font-size: 16px;
    line-height: 22px;
    width: 41px;
    height: 22px;
    z-index: 1;

    @media screen and (max-width: $md) {
      font-size: 14px;
    }
  }
}

.dontHaveAcc {
  margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #C8CCDD;
  @include md {
    display: none;
  }

  a {
    font-weight: 600;
    text-decoration: none;
    color: #8C6CED;
  }
}

.forgot {
  color: #8C6CED;
  font-size: 14px;
  line-height: 19px;

  @media screen and (max-width: $md) {
    font-weight: 400;
  }

  @include md {
    color: #373D59;
  }
}

.inputBox {
  position: relative;

  .btnEye {
    & > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: -20px;
    }

    background: none;
    border: none;
    width: auto;
    height: auto;
    border-radius: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    height: 100%;
    left: auto;
    margin: 0;
    padding: 0 20px;
    height: 100%;

    &:hover {
      background: none;
      cursor: pointer;
    }

    @include md
  }
}

.input {
  padding: 9px 14px 12px !important;

  @media screen and (max-width: $md) {
    padding: 12px 14px 12px !important;
  }
}
