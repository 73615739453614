.button {
  width: 100%;
  padding: 10px 15px;
  border-radius: 12px;
}

.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red;
  padding-top: 8px;
}

.linkBtn {
  text-decoration: none;
  color: #C8CCDD;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
