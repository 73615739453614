
// Colors
$lightGrey: #717A9D;
$lightGrey2: #C8CCDD;
$darkGrey: #373D59;
$white: #fff;
$purple:#9A7CF9;
$lightPurple: #F0ECFF;
$primary: #8C6CED;
$error: #F85C56;

// Gradients
$purpleGradient: linear-gradient(180deg, #9B7EFA 0%, #7E5AE1 100%);
$contentBackground: linear-gradient(117.74deg, #EFF5FC 4.95%, #F0EDF3 43.73%, #E5E7F6 72.05%, #E5E7F6 94.44%);

$xs: 374px;
$sm: 576px;
$md: 768px;
$md2: 900px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin xs {
  @media (min-width:$xs) {
    @content
  }
};
@mixin sm {
  @media (min-width:$sm) {
    @content
  }
};
@mixin md {
  @media (min-width:$md) {
    @content
  }
};
@mixin md2 {
  @media (min-width:$md2) {
    @content
  }
};
@mixin md2-mw {
  @media (max-width:$md2) {
    @content
  }
};
@mixin lg {
  @media (min-width:$lg) {
    @content
  }
};
@mixin xl {
  @media (min-width:$xl) {
    @content
  }
};
@mixin xxl {
  @media (min-width:$xxl) {
    @content
  }
};

@mixin starIconHover {
    &:hover {
      transform: scale(1.1);

      & svg {
        path {
          fill: #FFDA18;
          stroke: #FFDA18;
        }
      }
    }
}
