@import "../../global/vars.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: 0 20px;
  background: #fff;
  @include md{
    padding: 40px 0;
    justify-content: center;
    align-items: center;
  }
}

.checkWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @include md{
    max-width: 367px;
    align-items: center;
    margin-bottom: 140px;
    :global{
      .formControls{
        margin-bottom: 32px;
      }
    }
  }
}

.logo {
  display: none;
  @include md {
    width: 160px;
    margin-bottom: 32px;
    display: block;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.checkTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: 11px;
  margin-bottom: 8px;
  line-height: 37px;
  width: 100%;
  color: #c8ccdd;
  font-weight: 400;
  & > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #c8ccdd;
  }
  a {
    text-decoration: none;
    color: #c8ccdd;
  }
  @include md {
    justify-content: center;
    margin-bottom: 4px;
  }
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #373d59;
    margin: 0;
    @include md {
      font-weight: 700;
      font-size: 20px;
    }
  }
}

.checkSubtitle {
  font-weight: 100;
  font-size: 14px;
  line-height: 19px;
  color: #c8ccdd;
  margin-bottom: 62px;
  max-width: 292px;
  @include md {
    padding: 0 20px;
    margin-bottom: 36px;
    text-align: center;
    max-width: 100%;
  }
}

.btnGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  button {
    width: 100%;
    border-radius: 12px;
    font-size: 16px;
    line-height: 19px;
  }
}

:global{
  .verification-input{
    --ReactInputVerificationCode-itemWidth: 38px;
    --ReactInputVerificationCode-itemHeight: 31px;
    margin-bottom: 55px;
    @include md{
      margin-bottom: 65px;
    }
    .ReactInputVerificationCode__container{
      width: 100%;
    }

    .ReactInputVerificationCode__item {
      position: relative;
      color: #262626;
      font-weight: 500;
    }

    .ReactInputVerificationCode__item,
    .ReactInputVerificationCode__item.is-active{
      box-shadow: none;
    }

    .ReactInputVerificationCode__item:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 1px;
      background-color: #ebebeb;
      transition: background-color 0.2s ease-out;
      @include md{
        height: 2px;
      }
    }

    .ReactInputVerificationCode__item.is-active:after {
      background-color: $purple;
    }
    
  }
}
.backLink{
  position: relative;
  z-index: 1;
  &Block{
      position: relative;
      width: 100%;
      height: 105px;
      margin-bottom: 51px;
      padding: 48px 24px 36px;
      @include md{
          display: none;
      }
      &::after{
          content: '';
          display: block;
          width: 620px;
          height: 330px;
          border-radius: 50%;
          background: linear-gradient(151.02deg, #9B7EFA 41.13%, #7E5AE1 82.18%);
          transform: rotate(350deg);
          position: absolute;
          bottom: 6px;
          left: -175px;
      }
  }
}