@import "../../../global/vars.scss";

.title {
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #373d59;
    @include md {
      font-size: 32px;
    }
  }
}

.container {
  min-height: 60vh;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  padding: 23px 15px;
  @include md {
    border-radius: 16px;
    padding: 20px;
  }
}
.top {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  @include md {
    flex-direction: column;
    align-items: flex-start;
  }
}
.left {
  flex: 1;
  padding-bottom: 13px;
  @include lg {
    padding-right: 16px;
    padding-bottom: 0;
  }
  @include xl {
    padding: 26px 6rem 0 14px;
  }
  .formControls {
    margin-bottom: 11px;
    @include md {
      margin-bottom: 34px;
    }
  }
}
.right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  @include lg {
    width: 349px;
    border-top: none;
  }
  @include xl {
    width: 385px;
  }
  &Form {
    padding-top: 32px;
    @include lg {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      padding: 4rem 0px 7rem 20px;
    }
    @include xl {
      margin-top: 30px;
      padding: 62px 46px 2.7rem 56px;
    }
  }
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 2rem 0 0 0;
  @include lg {
    padding: 2rem 0 0 20px;
  }
  @include xl {
    padding: 2rem 45px 0 20px;
  }
}
.btnCreate {
  width: 100%;
  @include md {
    width: 162px;
  }
}

.checkBox {
  margin-top: 1.2em;
}

.rightButtonGroup {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  @include md {
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }
}

.miniTitle {
  display: block;
  color: #a0aec0;
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;
  margin-bottom: 15px;
  @include md {
    margin-bottom: 25px;
  }
}

.helperInfo {
  color: $lightGrey2;
  font-size: 12px;
}

.modalTitleContainer {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 670px;

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 20px;
    }
  }
}

.modalDivider {
  margin: 0 -20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalButton {
  align-self: flex-end;
  width: 220px;
}

.colorsContainer {
  span {
    font-weight: 500;
    font-size: 14px;
    display: block;
    margin-bottom: 20px;
  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.sphere {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.add {
    border: 1px solid #acacac;
    box-sizing: border-box;
  }
}
.backBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #373d59;
  cursor: pointer;
  margin-right: 15px;
  background: none;
  border: none;
  outline: none;
  @include md {
    margin-right: 0;
  }
}
.select {
  & > div {
    width: 100%;
    max-width: 170px;
    & > div {
      min-height: 38px;
    }
    @include xs {
      max-width: 203px;
    }
  }
  label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
  }
  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    width: 100%;
    max-width: 170px;
    padding: 2px 16px 3px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 11px 15px 11px;
    @include xs {
      max-width: 203px;
    }
  }
  label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
  }
  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red;
  padding-top: 8px;
  &.validationRight {
    display: block;
    text-align: right;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > input {
    @include xs {
      max-width: 203px;
    }
    max-width: 170px;
  }
}

.smallForm {
  margin-top: 12px;
  @include xl {
    margin-top: 28px;
  }
  span {
    display: block;
    text-align: right;
  }
}
