@import "../../global/vars.scss";

.title {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 23px;
  margin-top: 13px;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    margin-left: 17px;
    @include sm {
      margin-left: 0;
    }
  }

  @include sm {
    justify-content: space-between;
  }
  @include md {
    padding-right: 11px;
    margin-top: 6px;
    h2 {
      font-size: 32px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px 16px 0;

  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .right {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  @include lg {
    align-items: center;
    flex-direction: row;
    .left {
      justify-content: flex-start;
      margin-bottom: 0;

      & > div:first-child {
        margin-right: 34px;
      }
    }
    .right {
      margin-bottom: 0;
    }
  }
}

.body {
}

.container {
  padding: 0 !important;
  @include lg {
    padding: 28px 53px 32px 46px !important;
  }
}

.actionBtn {
  button {
    margin: 0 !important;
  }
}

.monthSlider {
  display: flex;
  align-items: center;

  .month {
    text-align: center;
    width: 120px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    margin: 0 16px;
    line-height: 20px;
    @include xl {
      margin: 0 23px;
      font-weight: 700;
      font-size: 24px;
      line-height: 43px;
    }
  }

  .spehere {
    &:hover {
      cursor: pointer;
    }

    position: relative;
    width: 9px;
    height: 17px;

    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }

    @include xl {
      width: 40px;
      height: 40px;
      border: 1px solid #e2e8f0;
      border-radius: 50%;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 12px;
      }
    }
  }
}

table.calendar {
  width: 100%;
  color: #969696;
  border-spacing: 0;
  border-collapse: separate;
  cursor: pointer;
}

th.calendar {
  border: none;
  height: 60px;
  font-weight: 500;
  font-size: 14px;
}

td.calendar {
  position: relative;
  height: 125px;
  vertical-align: top;
  width: 137px;
  border: 0.5px solid #e2e8f0;
  @include lg {
    padding: 9px 9px 10px;
    .value {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.selectBadgeYear {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 89px;
}

.calendarTab {
  height: auto;

  button {
    padding: 7px 10px;
    line-height: normal;
    border: 2px solid transparent;
    border-radius: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    @include xs {
      padding: 7px 14px;
    }
    @include sm {
      padding: 8px 20px;
    }
  }
}

.badgeContainer {
  width: 90%;
  position: absolute;
  left: 9px;
  bottom: 9px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 76px;

  .badge {
    color: white;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    overflow: hidden;
    white-space: nowrap;
    width: max-content;
    max-width: 90%;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: max(0.8vw, 12px);
    padding: 3px 4px;
    border-radius: 4px;
  }
}

th.weeklyHeader {
  background: #f8f6ff;
  border: 1px solid #e2e8f0;

  &.active {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #8c6ced;
  }

  .weekDayContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sphere {
      width: 5px;
      height: 5px;
      background-color: #8c6ced;
      border-radius: 50%;
      margin-top: 4px;
      cursor: pointer;
    }
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:not(:first-child) {
    border-left: none;
  }

  &:not(:last-child) {
    border-right: none;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.weeklyTable {
  padding: 0 16px 16px 16px;
  margin-top: 32px;
  min-width: 895px;

  tr:nth-child(2) {
    td {
      border-top: none;
    }
  }
}

.days {
  td {
    height: 60px;

    &.hourColumn {
      border-left: none;

      span {
        font-weight: 400;
        font-size: 12px;
        white-space: nowrap;
        position: absolute;
        padding-right: 8px;
        display: block;
        background-color: $white;
        z-index: 1;
        bottom: -25%;
        min-width: 56px;
        text-align: right;
        transform: translateY(-45%);
      }
    }
  }
}

.event {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  margin-top: 2px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow-wrap: break-word;
  background: #ede7fc;
  overflow-y: scroll;

  .borderTop {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #8c6ced;
    height: 2px;
    border-radius: 16px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin: 0;
    padding: 12px 9px;
  }
}

.modalButton {
  margin-top: 47px;
  align-self: flex-end;
  width: 100%;
  @include md {
    width: 222px;
  }
}

.checkBox {
  margin-bottom: 24px;

  span {
    height: 16px !important;
    width: 16px !important;
    min-width: 16px !important;
    border-radius: 50% !important;
    background-size: 7px !important;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #c7c8cc !important;
    align-items: flex-start;

    p {
      display: block;
    }
  }
}
