@import "../../global/vars.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  @include lg {
    flex-direction: row;
  }
}

.left {
  display: contents;
  width: 100%;

  & .label {
    margin-left: 14px;
  }

  & .field {
    border: 1px solid transparent !important;
    transition: 300ms ease-in-out;

    &__title {
      font-size: 20px !important;
      font-weight: 700 !important;
      line-height: 30px !important;
    }

    &__focus {
      border: 1px solid $primary !important;
    }

    &__error {
      border: 1px solid $error !important;
    }
  }

  @include lg {
    padding: 33px 60px 0 23px;
    line-height: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  p {
    margin: 0;
    order: 1;
    &.title {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin: 0 0 24px;
      @include lg {
        font-size: 20px;
        margin: 0 0 27px;
      }
    }
  }
  span {
    display: block;
    color: #a0aec0;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    margin-bottom: 8px;
    order: 1;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #373d59;
    order: 1;
    @include lg {
      font-size: 16px;
    }
  }
}
.right {
  margin-top: 36px;
  order: 1;
  padding-top: 36px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  @include lg {
    padding: 0 30px;
    margin: 0;
    border-top: none;
    row-gap: 37px;
    display: flex;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
  }
  @include xl {
    padding: 12px 70px 0 58px;
    margin-top: 20px;
  }
  span {
    display: block;
    @include lg {
      margin-bottom: 8px;
    }
  }
  & > div {
    @include lg {
      width: 203px;
    }
  }
  :global{
    .b__menu span{margin-bottom: 0;}
  }
}

.button {
  order: 2;
  margin-top: 45px;
  img {
    margin-right: 5px;
    filter: brightness(2);
  }
  &:hover {
    img {
      filter: none;
    }
  }
  @include lg {
    max-width: 255px;
    margin-top: 30px;
  }
}

.category {
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  @include lg {
    flex-direction: column;
    align-items: flex-start !important;
  }
  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #373d59;
    @include lg {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 10px;
      line-height: 150%;
      text-transform: uppercase;
      color: #a0aec0;
    }
  }
  &Badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: $purple;
    color: $white;
    width: fit-content !important;
    height: 46px;
    padding: 0 16px;
    text-align: center;
    max-width: 170px;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;

    @include xs {
      max-width: 203px;
    }
    @include lg {
      width: auto;
    }
  }
}
.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include lg {
    flex-direction: column;
    align-items: flex-start !important;
  }
  & > div {
    width: 100%;
    max-width: 170px;
    @include xs {
      max-width: 203px;
    }
    & > div {
      min-height: 38px;
    }
  }
  & > label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
    display: block;
    @include lg {
      margin-bottom: 8px;
      text-align: left;
      font-weight: 700;
      font-size: 10px;
      line-height: 150%;
      text-transform: uppercase;
      color: #a0aec0;
    }
  }
  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include lg {
    flex-direction: column;
    align-items: flex-start !important;
  }
  & > div {
    width: 100%;
    max-width: 170px;
    padding: 2px 16px 3px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 11px 15px 11px;
    @include xs {
      max-width: 203px;
    }
  }
  label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
    display: block;
    @include lg {
      margin-bottom: 8px;
      text-align: left;
      font-weight: 700;
      font-size: 10px;
      line-height: 150%;
      color: #a0aec0;
      text-transform: uppercase;
    }
  }
  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.modalDelete {
  max-width: 576px !important;
}

.deleteBtnModal {
  width: 120px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  img {
    margin-right: 10px;
    filter: brightness(5);
  }
  &:hover {
    background-color: #fff;
    color: #f85c56;
    img {
      filter: none;
    }
  }
}

.deleteModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.deleteModalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.deleteModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 40px;
  @include lg {
    padding: 0 25px 42px;
  }
  & > img {
    width: 45px;
    display: block;
    margin-bottom: 18px;
    @include lg {
      margin-bottom: 22px;
    }
  }
  & > p {
    margin: 0;
    max-width: 250px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
    @include lg {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 22px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
}

.btnContainer {
  display: flex;
  column-gap: 26px;
}

.noBtnModal {
  width: 120px;
  outline: none;
  background-color: #8c6ced;
  border: 1px solid #8c6ced;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  img {
    margin-right: 10px;
    filter: brightness(5);
  }
  &:hover {
    background-color: #fff;
    color: #8c6ced;
    img {
      filter: none;
    }
  }
}
