@import '../../../../components/global/vars.scss';

.container {
  position: relative;
}

.top {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.375rem;
  cursor: pointer;
  pointer-events: all;

  &__title {
    font-family: 'Noto Sans', cursive;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.36;
    color: $darkGrey;
  }

  &__question {
    position: relative;
    align-items: center;

    &__icon {
      display: flex;
      align-items: center;
    }

    &__hint {
      position: absolute;
      width: 211px;
      background-color: $darkGrey;
      border-radius: 8px;
      color: $white;
      padding: 16px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -20%) scale(0.5);
      opacity: 0;
      transition: 300ms ease-in-out;
      z-index: 10;
      cursor: pointer;
      pointer-events: none;

      & > p {
        position: relative;
        z-index: 1;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.36;
      }

      &::before {
        content: '';
        position: absolute;
        width: 28px;
        height: 28px;
        background-color: #373D59;
        left: 50%;
        top: 0;
        z-index: 0;
        transform: translate(-50%, -5px) rotate(45deg);
      }
    }
  }

  &:hover {
    .top__question__hint {
      opacity: 1;
      transform: translate(-50%, 28px) scale(1);
      pointer-events: all;
    }
  }
}

.field {
  display: flex;
  align-items: center;
  width: 200px;
  height: 45px;
  padding: 0 1rem;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 0.5rem;
  color: $darkGrey;
  cursor: pointer;

  @media screen and (max-width: $sm) {
    width: 100%;
  }

  &::placeholder {
    color: $darkGrey;
  }
}

.multiInput {
  position: relative;
  width: fit-content;
  pointer-events: all;
  cursor: pointer;

  @media screen and (max-width: $sm) {
    width: 100%;
  }

  &__arrow {
    position: absolute;
    right: 1rem;
    top: 45%;
    transform: translateY(-50%) rotate(45deg);
    border: solid #818181;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transition: 300ms ease-in-out;
    pointer-events: none;
  }

  &__labels {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    transform: translateY(-20%);
    left: 0;
    margin-top: 5px;
    padding: 0.5rem 0;
    gap: 1.25rem;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    opacity: 0;
    transition: 300ms ease-in-out;
    z-index: 1;
    pointer-events: none;

    &__label {
      position: relative;
      width: fit-content;
      font-family: 'Noto Sans', cursive;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      padding: 0.3rem 0.75rem;
      border-radius: 6px;
      transition: 300ms ease-in-out;
      pointer-events: none;

      &__icon {
        position: absolute;
        right: calc(-12px + 6px);
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        transition: 300ms ease-in-out;
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  &.opened {

    & .multiInput__arrow {
      transform: rotate(225deg);
    }

    & .multiInput__labels {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }

    & .multiInput__labels__label {
      pointer-events: all;
      cursor: pointer;
    }
  }
}
