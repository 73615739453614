@import "../global/vars.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  & .updateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    background: #8C6CED;
    box-shadow: 0 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 15px;
    cursor: pointer;
    z-index: 1;
    visibility: hidden;

    @media screen and (max-width: $md) {
      visibility: visible;
    }
  }

  &::before {
    content: '';
    position: fixed;
    width: 110vw;
    height: 110vh;
    top: -20px;
    left: -5vw;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    pointer-events: none;
    transition: 300ms ease-in-out;
  }

  &.overlay {
    @media screen and (max-width: $sm) {
      &:before {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  & .top {
    display: flex;
    align-items: center;
    flex-direction: row;
    //justify-content: space-between;

    @media screen and (max-width: $xl) {
      flex-direction: column;
      align-items: flex-start;
    }

    & h2 {
      padding-right: 30px;
    }

    & .SwitchTab {
      margin-right: 30px;
    }

    @include md2-mw {
      flex-direction: column;

      & h2 {
        margin-bottom: 15px
      }

      & .SwitchTab {
        width: -moz-fit-content;
        width: fit-content;
        margin: 0
      }
    }
  }

  & .toggles {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-grow: 1;

    @media screen and (max-width: $xl) {
      margin-bottom: 20px;
    }
  }

  & .circles {
    width: 100%;
    display: flex;
    gap: 56px;
    margin-bottom: 57px;

    @media screen and (max-width: $xl) {
      gap: 30px;
      justify-content: center;

      & > div {
        flex-direction: column;
      }
    }

    @media screen and (max-width: $md) {
      gap: 0;
      justify-content: space-between;
      margin-bottom: 30px;

      & > div {
        text-align: center;
      }
    }
  }

  & .tabs {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $xl) {
      font-size: 12px;
      line-height: 16.34px;
    }

    @media screen and (max-width: $sm) {
      gap: 30px;
    }

    &__tab:nth-child(1):after {
      background-color: #9B7EFA;
    }

    &__tab:nth-child(2):after {
      background-color: #FFCF70;
    }

    &__tab:nth-child(3):after {
      background-color: #4FD1C5;
    }

    &__tab {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      padding: 0 20px 12px 20px;
      text-align: center;
      color: #C8CCDD;
      transition: 300ms ease-in-out;
      cursor: pointer;

      @media screen and (max-width: $sm) {
        font-size: 14px;
        line-height: 20px;
        padding: 0 15px 12px 15px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background: #9B7EFA;
        transition: 300ms ease-in-out;
      }

      &::after {
        content: '';
        position: absolute;
        top: 25%;
        left: 6px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        opacity: 0.5;
        transition: 300ms ease-in-out;

        @media screen and (max-width: $sm) {
          left: 3px;
        }
      }

      &.active {
        color: #8C6CED;

        &:before {
          width: 100%;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .toggleContainer {
    display: flex;
    align-items: center;
    position: relative;

    &__hint {
      position: absolute;
      width: 211px;
      background-color: $darkGrey;
      border-radius: 8px;
      color: $white;
      padding: 16px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 20%) scale(0.5);
      opacity: 0;
      transition: 300ms ease-in-out;
      z-index: 10;
      pointer-events: none;

      &__link {
        color: #2F80ED;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.36;
        padding: 0;
        display: inline-block;
        border: none;
        background: none;
        text-decoration: underline;
        cursor: pointer;
      }

      & > p {
        position: relative;
        margin: 0;
        z-index: 1;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.36;
      }

      &::before {
        content: '';
        position: absolute;
        width: 28px;
        height: 28px;
        background-color: #373D59;
        left: 50%;
        top: 0;
        z-index: 0;
        transform: translate(-50%, -5px) rotate(45deg);
      }
    }

    &:hover {
      .toggleContainer__hint {
        opacity: 1;
        transform: translate(-50%, 45px) scale(1);
        pointer-events: all;
      }
    }
  }

  & .toggle {
    margin: 0 !important;

    &__name {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      margin-left: 10px;

      @media screen and (max-width: $sm) {
        font-size: 14px;
        line-height: 19.07px;
        margin-left: 6px;
      }
    }

    input[type="checkbox"] + label:after {
      margin-left: 7px;
    }

    input[type="checkbox"] + label:before {
      width: calc(50px - 2 * 2px);
      height: calc(28px - 2 * 2px);
    }

    input[type="checkbox"]:checked + label:after {
      margin-left: calc(100% - 15px - 5px);
    }

    @media screen and (max-width: $sm) {
      width: 36px;

      &__name {
        margin-left: 12px;
      }

      & label {
        height: 19px !important;
        width: 36px !important;

        &:before {
          height: 19px !important;
          width: 36px !important;
        }

        &:after {
          width: 14px !important;
          height: 14px !important;
        }
      }

      input[type="checkbox"] + label:after {
        margin-left: 5px;
      }

      input[type="checkbox"]:checked + label:after {
        margin-left: calc(100% - 10px - 5px);
      }
    }
  }

}

.campaignsContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  flex-grow: 1;

  @media screen and (max-width: $xl) {
    padding: 30px 20px;
  }

  @media screen and (max-width: $lg) {
    margin: 50px -11px 0 -11px;
  }

  @media screen and (max-width: $sm) {
    margin: 1rem -11px 0 -11px;
  }

  &__overflow {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
  }
}

