@import "../../../global/vars.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @include lg{
    padding: 20px 35px 0 20px;
  }
}

.formContainer{
  width: 100%;
  & > div {
    margin-bottom: 32px;
  }
  @include lg{
    max-width: 76%;
  }
  @include xl{
    padding-bottom: 110px;
  }
}

.rightButtonGroup {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  gap: 10px;
  @include md {
    flex-direction: row;
    justify-content: flex-end;
  }
  @include xl{
    margin-bottom: -30px;
  }
}

.btnSave {
    width: 100%;
    @include md {
      width: 162px;
    }
  }

.editor{
  min-height: 254px;
}


.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red;
  padding-top: 8px;
}