@import "../global/vars";
.notifications{
  height: 100%;
  width: 100%;

  background: rgba(255, 255, 255, 0.39);
  border: 2px solid rgba(255, 255, 255, 0.43);
  padding: 8px;
  border-radius: 20px;
  @include md{

    padding: 20px;
    width: calc(100% -  40px);
    margin: 20px;
  }
}
.h1{
  margin-bottom: 24px;
  @include md{
    margin-bottom: 10px;
    margin-left: 20px;
    margin-top: 10px;
  }
  letter-spacing: -0.6px;
}
.empty{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height:1.5;
  font-size: 14px;
  color: #A5A5A5;
  img{
    width:100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
  &Img{
    width: 100%;
    height: 35vh;
    max-height: 318px;
    position: relative;
    
  }
  :global{
    .center{
      width:80%;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .title{
      font-size:  24px;
    }
    .subtitle{
      font-size: 14px;
      @include md{
        font-size: 16px;
      }
    }

  }
}


.gridUser{
  cursor: pointer;
  &:hover{
    background-color: #fcf9ff;
  }
  min-height: 90px;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0  3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  display: grid;
  margin-bottom: 16px;
  grid-template-areas:
              "img  name time    "
            " img  message  counter  ";

  grid-template-columns: 55px 1fr 36px;
  grid-template-rows: 1fr 1fr;
  gap: 12px 8px;
  font-size: 14px;
  line-height: 1;
    :global{
      .message , .name{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top: 5px;
        @include md{
          padding-right: 5px;
        }
      }
      .name{
        font-size: 16px;
        font-weight: 600;
      }

      .time , .counter{
        display: flex;
        justify-content: flex-end;
      }
      .time{
        font-size: 14px;
        margin-top: 10px;
      }
      .message{
        font-size: 14px;
      }
      .counter{
        .full{
          height: 24px;
          width: 24px;
          border-radius: 50%;
          color: white;
          line-height: 1;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #373D59;
        }

      }
      .img{
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
          img{
            height: 53px;
            width: 53px;
            object-fit: cover;
            border-radius: 50%;
          }
      }

      .img{     grid-area: img}
      .name{    grid-area: name}
      .message{ grid-area: message}
      .time{    grid-area: time}
      .counter{ grid-area: counter}
    }
}
