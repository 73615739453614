@import '../../../components/global/vars';

.container {
  position: relative;

  @media screen and (max-width: $sm) {
    width: 100%;
  }

  &__period {
    display: flex;
    gap: 25px;
    background: #FFFFFF;
    padding: 9px 20px;
    border: 1px solid #E2E8F0;
    border-radius: 15px;
    cursor: pointer;

    @media screen and (max-width: $sm) {
      gap: unset;
      justify-content: space-between;
      margin: 0 10px;
    }

    & .icon {
      width: 12px;
      height: 10px;
      transition: 300ms ease-in-out;

      &.icon__down {
        transform: rotate(180deg);
      }
    }
  }

  & .calendar {
    position: absolute;
    min-width: 100%;
    right: 0;
    padding: 30px;
    transform: translateY(-15%);
    opacity: 0;
    background: #FFFFFF;
    box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);
    border-radius: 16px;
    transition: 300ms ease-in-out;
    z-index: 1;
    pointer-events: none;
    overflow: hidden;

    &.calendar__open {
      opacity: 1;
      z-index: 10;
      transform: translateY(7px);
      pointer-events: all;

      @media screen and (max-width: $sm) {
        transform: translateY(-20%);
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 0.8px solid #E4E5E7;

      &__date {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.01em;
        text-align: center;
        color: #0F2552;
      }

      &__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 55px;

        &__arrow {
          border: solid #848A95;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          transition: 300ms ease-in-out;
          cursor: pointer;

          &:hover {
            border: solid #2F80ED;
            border-width: 0 2px 2px 0;
            padding: 4px;
          }

          &__right {
            transform: rotate(-45deg);
          }

          &__left {
            transform: rotate(135deg);
          }
        }
      }
    }

    .buttons {
      display: none;
      visibility: hidden;
      justify-content: center;
      gap: 7px;
      margin-top: 50px;

      @media screen and (max-width: $sm) {
        display: flex;
        visibility: visible;
      }

      & .button {
        width: 50%;
        margin: 0;

        &__cancel {
          transition: 300ms ease-in-out;
          color: $primary;
          border: 2px solid transparent;
          background: linear-gradient(to right, white, white),
          $purpleGradient;
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;

          &:hover {
            color: #fff;
            background: $purpleGradient,
            $purpleGradient;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
          }

        }
      }
    }

    & .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 162px;
      height: 46px;
      margin: 30px auto 0 auto;
      border-radius: 15px;
      color: $white;
      font-size: 1rem;
      font-weight: 600;
      background: $purpleGradient;
    }

    & .grid {
      display: grid;
      grid-template-columns: repeat(7, 24px);
      grid-template-rows: repeat(7, 24px);
      gap: 14px;
      overflow: hidden;

      @media screen and (max-width: $sm) {
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(7, 29px);
      }

      &__day {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #7E818C;
      }

      &__cell {
        width: 24px;
        height: 24px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 16.71px;
        color: #0F2552;
        cursor: pointer;

        &__startDate {
          background: #8C6CED;
          border-radius: 100%;
          color: white !important;

          &.grid__cell__period::before {
            left: 50%;
          }
        }

        &__endDate {
          background: #8C6CED !important;
          color: white !important;
          border-radius: 100%;

          &.grid__cell__period::before {
            right: 50%;
            left: unset;
            width: 24px;
          }
        }

        &__period {
          color: white !important;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background: #d6ccf8;
            z-index: -1;

            @media screen and (max-width: $sm) {
              width: 15vw
            }
          }
        }

        &__notCurrentMonth {
          //pointer-events: none;
          color: #7E818C;
        }
      }
    }
  }
}
