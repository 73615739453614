@import "../../../global/vars.scss";

table.miniCalendar {
  border-collapse: collapse;
  width: 100%;
}

td.miniCalendar {
  text-align: center;
  cursor: pointer;

  &.disabled {
    color: $lightGrey2;
  }

  border: 0.5px solid #e2e8f0;
  width: 48px;
  height: 54px;
  position: relative;
  @include sm {
    width: 75px;
    height: 62.42px;
  }

  &:hover {
    background-color: #f8f7ff;
  }
}

th.miniCalendar {
  &.header {
    height: 37px !important;
    color: #595959;
    @include sm {
      height: 55px !important;
    }
  }

  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: inherit;
  height: 55.5px;
  border-top: 0.5px solid #e2e8f0;
  border-bottom: 0.5px solid #e2e8f0;
}

.events {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  height: 24px;
  align-items: flex-end;

  .sphere {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    cursor: pointer;
    transform: translateY(-8px);

    @include sm {
      width: 8px;
      height: 8px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.date {
  left: 50%;
  transform: translateX(-50%);
  top: 12.2px;
  position: absolute;
  @include lg {
    font-weight: 500;
    font-size: 14px;
    line-height: 8px;
  }
}

.eventContainer {
  padding: 9px 11px;
  background-color: $white;
  border-radius: 4px;
  position: absolute;
  @include xs {
    width: 264px;
  }
  width: 140px;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
  left: 50%;
  transform: translateX(-50%);
  //top: 100%;
  z-index: 99;

  .event {
    text-align: left;
    padding: 5px 8px;
    width: 100%;
    font-size: 12px;
    border-radius: 7px;
    cursor: pointer;
    color: #fff;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.leftButtonGroup {
  display: flex;
  color: #a5a5a5;
  font-weight: 500;
  font-size: 16px;
  transition: 300ms ease-in-out;

  &:hover {
    color: #FF0000;
    cursor: pointer;

    & > img {
      filter: invert(12%) sepia(100%) saturate(7298%) hue-rotate(2deg) brightness(112%) contrast(116%);
    }
  }

  & > img {
    transition: 300ms ease-in-out;
    margin-right: 12px;
  }
}

.modalTitleContainer {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include md {
    padding: 24px 37px 25px;
  }

  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $darkGrey;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalWrapper {
  width: 100%;
  max-width: 740px;
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 23px 16px 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 32px 36px 27px;
  }
}

.modalButton {
  margin-top: 47px;
  align-self: flex-end;
  width: 100%;
  @include md {
    width: 222px;
  }
}

.colorsContainer {
  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    margin-bottom: 8px;
    @include md {
      margin-bottom: 19px;
    }
  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.sphere {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }

  &.add {
    border: 1px solid #acacac;
    box-sizing: border-box;
  }
}

.formControls {
  margin-bottom: 15px;
}

.datePick {
  column-gap: 41px;
  flex-wrap: wrap;
  margin-top: 30px;
  @include md {
    flex-wrap: nowrap;
  }

  .durationWrapper {
    height: max-content;

    & > label {
      margin-bottom: 16px;
    }
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    @include md {
      margin-bottom: 0;
      width: auto;
      flex-direction: column;
      align-items: flex-start;
    }

    & > div {
      width: 170px;
      margin-bottom: 0;
      @include xs {
        width: 203px;
      }
      @include md {
        width: auto;
      }
    }
  }

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #373d59;
    display: block;
    @include md {
      margin-bottom: 16px;
    }
  }

  .day {
    height: max-content;

    .datePicker {
      height: 45px;
      //display: flex;
      //flex-direction: column;
    }
  }

  .hour {
    height: max-content;
  }

  .minute {
    height: max-content;
  }

  .horizontalDates {
    display: flex;
    align-items: center;

    & > input {
      max-width: 108px;
    }
  }

  .divider {
    margin: 0 8px;
    width: 16px;
    height: 0;
    border: 1px solid #e5e5e5;
  }

  .mobile {
    padding-top: 0;
    margin-bottom: 12px;
    display: block;
    text-align: right;
    width: 100%;
  }

  .duration {
    height: max-content;

    .select {
      width: 170px;
      @include xs {
        width: 203px;
      }
      @include md {
        width: 150px;
      }

      & > div {
        display: flex;
        width: 100%;

        & > div {
          width: 100%;
        }
      }
    }
  }
}

.checkBox {
  span {
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    color: #c7c8cc !important;
  }
}

.rightButtonGroup {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: space-between;
  gap: 18px;
  align-items: center;

  button {
    width: 162px;
  }

  @include md {
    flex-direction: row;
  }
}

.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red !important;
  padding-top: 8px;
}

.modalDelete {
  max-width: 576px !important;
}

.deleteBtnModal {
  width: 120px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #f85c56;

    img {
      filter: none;
    }
  }
}

.deleteModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.deleteModalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.deleteModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 40px;
  @include lg {
    padding: 0 25px 42px;
  }

  & > img {
    width: 45px;
    display: block;
    margin-bottom: 18px;
    @include lg {
      margin-bottom: 22px;
    }
  }

  & > p {
    margin: 0;
    max-width: 250px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
    @include lg {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 22px;
    }
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
}

.btnContainer {
  display: flex;
  column-gap: 26px;
}

.noBtnModal {
  width: 120px;
  outline: none;
  background-color: #8c6ced;
  border: 1px solid #8c6ced;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #8c6ced;

    img {
      filter: none;
    }
  }
}
