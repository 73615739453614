@import '../vars';
.pagination{
  text-align: center;
  display: flex;
  list-style: none;
  justify-content:center;
  padding:0;
  margin: 0;
  :global{
    .page-item{
      flex-shrink: 0;
      margin:0 1.5px;
      color:#5D606B;
      &:first-child{
        margin-right: 7px;
        transform: rotate(180deg);
        @include md{
          margin-right: 2px;
          svg{
            height: 15px;
          }
        }
      }
      &:last-child{
        margin-left: 7px;
        @include md{
          svg{
            height: 15px;
          }
        }
      }
      a{
        height: 26px;
        width: auto;
        border-radius: 100px;
        font-size: 16px;
        line-height: 1;
        padding: 0 8px;
        @include md{
          font-size: 14px;
          height:18px;
          padding: 0 5px;
        }
        display: flex;
        justify-content: center;
        align-items: center;

      }

      &:last-child.disabled{
         svg path{
           stroke:   #C8CCDD;
         }
      }

      &:first-child.disabled{
        svg path{
          stroke:   #C8CCDD;
        }
      }

      &.active{
         a{
           height: 25px;
           width: auto;
           color: white;
           background: linear-gradient(180deg, #9B7EFA 0%, #7E5AE1 100%);
            @include md{
              height: 18px;
            }
         }
      }
    }
  }
}
