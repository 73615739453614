@import "../../global/interactions.scss";
@import "../../global/vars.scss";

@mixin smaller-font {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  @content
}

.campaigns {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 550px;
  min-height: 340px;
  background: rgba(255, 255, 255, 0.39);
  border-radius: 33px;
  overflow-x: scroll;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-top: 1.5px solid #fff;
    border-bottom: 1.5px solid #fff;
    background: #E2E8F0;
  }
}

.customTable {
  width: 100%;
  --company-name-width: 43px;
  --border: 0.5px solid #e2e8f0;

  ::-webkit-scrollbar {
    display: none;
  }

  & .row {
    width: 100%;
    display: flex;


    &__main {
      position: relative;
      cursor: pointer;
      transition: 500ms ease-in-out;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--cells-width);
        height: calc(100% - 1px);
        border: 1px solid #b9ace5;
        border-radius: 6px;
        background-color: #b9ace51a;
        opacity: 0;
        transition: 500ms ease-in-out;
      }

      &__selected {

        & .companyIcon {
            transform: scale(1.1);
        }

        &:before {
          opacity: 1;
        }
      }
    }

    & .borderTop {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 0.5px;
        background: #e2e8f0;
      }
    }

    &__top {
      //padding-left: calc(var(--company-name-width) - 1px);

      & .cell {
        min-width: 150px !important;
        text-transform: uppercase;
        border-top: var(--border);
        @include smaller-font {
          font-size: 14px !important;
        }
        color: #A5A5A5;
      }

      & .emptyCell {
        min-width: 42.5px;
        border-right: var(--border);
        border-bottom: var(--border);
      }
    }

    & .cell {
      width: 100%;
      min-width: 100px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding-left: 8px;
      padding-right: 8px;
      text-align: left;
      height: 50px;
      border-bottom: var(--border);

    }

    .companyIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 42.5px;
      width: 42.5px;
      padding: 10px;
      border-right: var(--border);
      transition: 300ms ease-in-out;
    }

    .companyName {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      color: #A5A5A5;
    }

    .value {
      min-width: 150px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
      color: $darkGrey;

      &.name {
        position: relative;

        & .hint {
          position: absolute;
          white-space: nowrap;
          left: 0;
          bottom: 0;
          padding: 1rem;
          background: #fff;
          border-radius: 4px;
          transition: 300ms ease-in-out;
          opacity: 0;
          transform: translateY(120%);
          z-index: 1;
          pointer-events: none;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

          &:before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            left: 10%;
            top: -5px;
            transform: rotate(45deg);
            background: #fff;
            z-index: 1;
          }
        }

        &:hover .hint {
          opacity: 1;
          transform: translateY(100%);
        }
      }
    }
  }
}
