.badge{
    min-width: 84px;
    width: max-content;
    height: 25px;
    border-radius: 13px;
    padding: 2px 12px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 140px;
    display: inline-block;
    white-space: nowrap;
}