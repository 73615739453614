// Global styles

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  color: #373d59;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
#root {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.selected-flag {
  background-color: initial !important;
  &:hover {
    background-color: initial !important;
  }

  .arrow {
    margin-top: 1px !important;
  }
}

.reset {
  span,
  h1,
  h2,
  h3,
  h4,
  div {
    font-family: revert;
    color: revert;
    font-size: revert;
    font-weight: revert;
  }
}
h1,
h2 {
  font-size: 20px;
  line-height: 1.5;
  @include md {
    font-size: 32px;
  }
}

body {
  background: $contentBackground;
  @include md {
    background: white;
  }
  margin: 0;
  * {
    font-family: "Noto Sans";
  }
  .mainArea {
    width: 100%;
    @include md {
      background: $contentBackground;
    }
    padding-bottom: 80px;
    display: flex;
    @include md {
      flex-grow: 0;
      flex-shrink: 1;
      overflow: auto;
      width: 100%;
      border-radius: 38px 0px 0px 0px;
      padding-bottom: 0;
    }
    .container {
      padding: 10px 12px 47px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      @include md {
        padding: 31px 54px;
      }
    }
  }
}
.layout.layout {
  width: 100vw;
}
.modalClose {
  min-width: 14px;
}
// Containers
.f-full {
  flex: 1 0 auto;
}
.as-center {
  align-self: center;
}
.jc-end {
  justify-content: flex-end !important;
}
.jc-sb {
  justify-content: space-between !important;
}
.ai-center {
  align-items: center;
}
.d-flex {
  display: flex !important;
  position: relative;
  width: 100%;

  &.col {
    flex-direction: column;
  }

  &.jc-center {
    justify-content: center;
  }

  &.ai-end {
    align-items: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}
.d {
  @mixin display {
    &-block {
      display: block !important;
    }
    &-inline {
      display: inline-block !important;
    }
    &-none {
      display: none !important;
    }
    &-flex {
      display: flex !important;
    }
  }
  @include display;
  &-sm {
    @include sm {
      @include display;
    }
  }
  &-md {
    @include md {
      @include display;
    }
  }
  &-lg {
    @include lg {
      @include display;
    }
  }
  &-xl {
    @include xl {
      @include display;
    }
  }
}
//width
.w {
  &-100 {
    width: 100%;
  }
  &-50 {
    width: 50%;
  }
  &-25 {
    width: 25%;
  }
}
.h {
  &-100 {
    height: 100%;
  }
}

// Boxes and blocks
.box {
  background-color: #fff;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  width: 100%;
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
  @include xl {
    min-height: 250px;
    width: calc(50% - 10px);
    min-width: 400px;
    // flex: 1;
  }
  h4.boxTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #373d59;
    margin: 0;
  }
}
