@import '../../global/vars.scss';
.login{
    &With{
        width: 30%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E2E8F0;
        padding: 0.6875rem 0;
        border-radius: 8px;
        cursor: pointer;
        transition: border-color .3s linear;

        @media screen and (max-width: $md){
            width: 102px;
        }

        &:hover{
            border-color: #8E6EEF;
        }
        img{
            object-fit: cover;
        }
        &Container{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}
