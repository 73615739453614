@import './vars.scss';
h1, h2, h3, h4{
    color: $darkGrey;
    margin: 0;
}

.select_option__sbFMk{
    width: max-content !important;
}

.select_optionWrapper__\+zr-z{
    background-color: transparent !important;
}

// .b__value-container{
//     display: block !important;
//     height: 20px;
// }

.css-4ljt47-MenuList{
    padding: 0 !important;
}

// Scrollbar
::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    background: transparent
}

::-webkit-scrollbar-thumb {
    background: $lightGrey2;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background: $purple;
}

select{
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    line-height: 38px;
    font-size: 1rem;
    color: $darkGrey;
    padding: 0px 4px 0px 12px;
    border: 1px solid $lightGrey2;

    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;

    &:focus,
    &:active,
    &:focus-visible {
        border: none;
        outline: none;
    }
}

textarea[type='text'] {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    resize: none;
    -o-border-radius: 6px;
    line-height: 38px;
    font-size: 1rem;
    color: $darkGrey;
    padding: 0px 4px 0px 12px;
    border: 1px solid $lightGrey2;

    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;

    &:focus,
    &:active,
    &:focus-visible {
        border: none;
        outline-color: $lightGrey;
    }
}

button{
    .reset{
        background: revert;
        color: revert;
        font-weight: revert;
        text-align: revert;
        line-height: revert;
        padding: revert;
        font-size: revert;
        border-radius: revert;
        -webkit-border-radius: revert;
        -moz-border-radius: revert;
        -ms-border-radius: revert;
        -o-border-radius: revert;
        border: revert;
    
        transition: all 0.25s;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
    
        &:hover {
            background: revert;
            color: revert;
            outline: revert;
        }
    }
    cursor: pointer;
    .focus {
        background: $purpleGradient;
        color: $white;
        font-weight: 600;
        text-align: center;
        line-height: 40px;
        padding: 0px 25px;
        font-size: 1rem;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        border: none;
    
        transition: all 0.25s;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
    
        &:hover {
            background: transparent;
            color: $purple;
            outline: 2px solid $purple;
        }
    }
}

//forms
form{
    a{
        text-decoration: none;
        color: #373D59;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }
}

.form{
    &Bottom{
        margin-bottom: 32px;
        @include md{
            margin-bottom: 30px;
        }
    }
    &Controls{
        text-align: left;
        margin-bottom: 16px;
        @include md{
            margin-bottom: 14px;
        }
        label{
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #373D59;
            display: block;
            margin-bottom: 5px;
        }
        input[type="text"],
        input[type="number"],
        input[type="email"],
        input[type="password"],
        textarea{
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #373D59;
            padding: 11px 14px 12px;
            border: 1px solid #E2E8F0;
            border-radius: 6px;
            resize: none;
            &::placeholder{
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: rgba(93, 96, 107, 0.51);
            }
            &:focus{
                border-color: #8C6CED;
                outline: none;
                &::placeholder{
                    color: transparent;
                }
            }
        }
        input[type="tel"]{
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #373D59;
            border: 1px solid #E2E8F0;
            border-radius: 6px;
            resize: none;
            &::placeholder{
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: rgba(93, 96, 107, 0.51);
            }
            &:focus{
                outline: none;
                &::placeholder{
                    color: transparent;
                }
            }
        }
        textarea{
            height: 170px;
            @include md{
                height: 150px;
            }
        }
        &.error{
            label{
                color: #FB5B55;

                & + div, & + input, & + textarea{
                    border-color: #FB5B55;
                }
            }
            input{
                border-color: #FB5B55;
            }
        }
        &.required{
            label{
                position: relative;
                display: flex;
                align-items: center;
                &::after{
                    content: '*';
                    color: #FB5B55;
                    font-size: 14px;
                    line-height: 19px;
                    margin-left: 10px;
                }
            }
        }
    }
    &Group{
        .formControls:last-child{
            margin-bottom: 22px;
            @include md{
                margin-bottom: 24px;
            }
        }
    }
}

//custom radio,checkbox

.custom{
    &Checkbox{
        &.error{
           span{
            border-color: #FB5B55;
           }
           label{
            color: #FB5B55;
           }
        }
        label{
            position: relative;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #373D59;
            display: flex;
            align-items: center;
            max-width: 100%;
            p{
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow-y: hidden;
                word-break: break-all;
            }
            span{
                display: inline-block;
                position: relative;
                width: 24px;
                height: 24px;
                min-width: 24px;
                border: 1px solid #A5A5A5;
                border-radius: 4px;
                margin-right: 8px;
                @include md{
                    border-color: #E2E8F0;
                }
            }
        }
        input{
            display: none;
            &:checked + span{
                border-color: #8C6CED;
                background-color: #8C6CED;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 14px;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.57339 8.99994C4.48198 8.99965 4.39161 8.98056 4.30788 8.94386C4.22416 8.90716 4.14888 8.85363 4.08672 8.78661L0.846724 5.33994C0.725608 5.21087 0.660727 5.03897 0.666353 4.86206C0.671979 4.68515 0.747652 4.51772 0.876724 4.39661C1.0058 4.27549 1.17769 4.21061 1.3546 4.21624C1.53151 4.22186 1.69894 4.29753 1.82006 4.42661L4.56672 7.35327L10.1734 1.21994C10.2303 1.14909 10.3011 1.09062 10.3814 1.04812C10.4617 1.00562 10.5499 0.979983 10.6405 0.972793C10.731 0.965604 10.8221 0.977009 10.9081 1.00631C10.9942 1.03561 11.0733 1.08218 11.1406 1.14317C11.208 1.20416 11.2622 1.27828 11.2999 1.36097C11.3375 1.44366 11.3579 1.53318 11.3597 1.62403C11.3615 1.71488 11.3448 1.80515 11.3104 1.88928C11.2761 1.97341 11.2249 2.04964 11.1601 2.11327L5.06672 8.77994C5.00515 8.84818 4.93013 8.90294 4.84638 8.9408C4.76263 8.97866 4.67196 8.9988 4.58006 8.99994H4.57339Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }
}
.sliderProjects, .sliderTask{
    .swiper{
        padding-bottom: 45px;
        height: min-content;
    }
    .swiper-pagination{
        bottom: 0;
        justify-content: center;
        display: flex;
        align-items: center;
        gap: 11px;
        .swiper-pagination-bullet{
            margin: 0;
            background: #C8CCDD;
            opacity: 1;
            &.swiper-pagination-bullet-active{
                background: #8C6CED
            }
        }
    }
}

.input-search{
    background-color: #FFFFFF;
    border: 0.5px solid #C8CCDD;
    border-radius: 15px;
    position: relative;
    background-image: url("../../media/icons/search.svg");
     background-position-x: 10px;
     background-position-y: center;
    background-size: 14px;
    background-repeat: no-repeat;
    height: 40px;
    width: 100%;
    padding: 10px 10px 10px 35px;
    &:focus{
        outline: none;
        background-color: #f8f8f8;
    }
    &.open{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
button{
    background-color: transparent;
    border:none;
}
.onlyMobile {
        @include md{
            display: none!important;
        }
}
.onlyMd{
    @media (max-width:$md ){
        display: none !important;
    }
}

.onlyLg{
    @media (max-width:$lg ){
        display: none !important;
    }
}

.onlyMobileAndTablet{
    @include lg{
        display: none!important;
    }
}