.timePicker {
  position: relative;
  width: fit-content;

 /* input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    visibility: hidden;
  }*/


  & .value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 47px;
    color: #373D59;
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    font-size: 16px;
    padding-left: 23px;
    padding-right: 5px;
  }

  &__options {
    position: absolute;
    left: 0;
    width: 100%;

    background-color: #fff;
    border: 1px solid #E2E8F0;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: flex;
    opacity: 0;
    transform: translateY(-10%);
    transition: 300ms ease-in-out;

    &__show {
      opacity: 1;
      transform: translateY(0);
    }

    & .hours, & .minutes {
      width: 50%;
      max-height: 100px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      border-right: 1px solid #E2E8F0;

      & .option {
        width: 100%;
        display: flex;
        justify-content: center;
        transition: 300ms ease-in-out;

        &:hover {
          background-color: #809bc4;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
