.emptyState {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: #F6F4FE;
  width: 100%;
  height: 100%;
  -webkit-border-bottom-left-radius: 16px;
  -webkit-border-bottom-right-radius: 16px;
}
