@import '../../../global/vars.scss';
@import '../../../global/interactions.scss';

.card {
  width: 100%;
  min-height: 150px;
  height: max-content;
  background-color: $white;
  border-radius: 6px 6px 10px 10px;
  padding: 32px 20px 25px;
  margin-bottom: 24px;
  position: relative;
  @include cardBoxShadow;

  @include sm {
    width: 308px;
  }

  &:hover, &:active {
    @include cardHover;
    cursor: grab;
  }

  &::before {
    top: 36px;
  }

  &::after {
    top: 46px;
  }
}

.categoryText {
  position: absolute;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 1px 6px 3px;
  border-radius: 0 0 6px 6px;
  top: -8px;
  left: 20px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__iconStar {
    cursor: pointer;
    transition: 300ms ease-in-out;
    @include starIconHover;

    & svg {
      transition: 500ms ease-in-out;

      path {
        transition: 500ms ease-in-out;
        fill: #fff;
        stroke: #FFDA18;
        stroke-width: 1;
      }
    }

    &__active {
      & svg {
        path {
          fill: #FFDA18;
          stroke: #FFDA18;
        }
      }
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;

  &:hover {
    cursor: pointer;
    color: $purple;
  }
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: block;
  color: rgba(93, 96, 107, 0.51);
}


.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 26px;
  gap: 5px 10px;
}

.newBadge {
  background: none;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  border: none;
  color: #C8CCDD;
  font-weight: 600;
  transition: all 0.3s linear;
  font-size: 19px;

  &:hover {
    color: #8C6CED;
    background: none;
  }
}

.dateContainer {
  min-height: 20px;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: $lightGrey2;
  margin-top: 30px;

  span {
    padding-left: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #C8CCDD;
  }
}
