@import "../global/vars";
.leaderboard {
  border-radius: 16px 16px 0 0;
  margin: 0 -20px;
  background-color: #fff;
  width: calc(100% + 40px);
  @include md {
    background: rgba(255, 255, 255, 0.39);
    border-radius: 33px;
    border: 2px solid rgba(255, 255, 255, 0.43);
    padding: 20px;
    width: calc(100% - 40px);
    margin: 20px auto 60px auto;
  }
  @include xl {
    width: calc(100% - 40px);
  }

  :global {
    .center {
      @include md {
        background: #fff;
        border-radius: 16px 16px 0 0;
      }
    }
    .lead-grid {
      gap: 2px 10px;
      font-size: 14px;
      border-bottom: 1px solid #e2e8f0;
      align-items: center;
      display: grid;
      grid-template-columns: 15px minmax(130px, 1fr) 105px 45px;
      @include md {
        grid-template-columns: 30px minmax(130px, 1fr) 105px 45px;
        &>*:nth-child(2){
          text-align: center;
        }
      }
      @include xl {
        grid-template-columns: 30px minmax(130px, 1fr) 240px 233px;
      }
    }
    .pagination {
      background-color: #fff;
      position: fixed;
      bottom: 80px;
      height: 71px;
      left: 0;
      right: 0;
      border-top: 1px solid #e2e8f0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include md {
        position: static;
        height: auto;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px;
        border-radius: 0 0 16px 16px;
      }
    }
    .select__container {
      display: none;
      @include md {
        display: unset;
      }
    }
    .titles {
      padding: 16px 20px 0;
      color: #a5a5a5;
      border-bottom: none;
      @include md {
        border-bottom: 1px solid #e2e8f0;
        padding: 18px 15px 11px;
      }
      :global {
        .profile_title {
          text-align: left;
          min-width: 150px;
        }
      }
      span:last-child {
        font-weight: 300;
      }
    }
  }
}

.headingContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  letter-spacing: -1px;
  margin-bottom: 20px;
  @include md {
    padding: 0 20px;
    letter-spacing: -1.4px;
    margin: 0 auto 2px;
    h1 {
      min-width: 850px;
      margin: 0 auto;
    }
  }
}

.deleteBtnModal {
  width: 105px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  img {
    margin-right: 10px;
    filter: brightness(5);
  }
  &:hover {
    background-color: #fff;
    color: #f85c56;
    img {
      filter: none;
    }
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}
.modalDelete {
  max-width: 576px !important;
}

.modalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 20px;
  row-gap: 20px;
  @include lg {
    padding: 0 16px 54px;
    row-gap: 19px;
  }
  & > img {
    width: 45px;
  }
  & > p {
    margin: 0;
    max-width: 350px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    @include lg {
      font-size: 20px;
    }
    b {
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    @include lg {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.upContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 27px 15px 23px;
  .search {
    display: flex;
    img {
      width: 26px;
      height: 26px;
    }
    input {
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
}

.frame {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr 26px;

  @include md {
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 33px;
    grid-template-rows: 50px 1fr 60px;
  }

  &.adminMobile {
    grid-template-rows: 120px 1fr 26px;
    @include sm {
      grid-template-rows: 100px 1fr 60px;
    }
    @include md {
      grid-template-rows: 50px 1fr 60px;
    }
  }
}

.admin {
  .frame {
    overflow: auto;
    max-width: 100%;
    border-radius: 16px 16px 0 0;
    @include md {
      border-radius: 16px;
    }
  }
  .adminMobile {
    border-radius: 16px;
  }
  &.headingContainer {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    @include md {
      padding: 0 25px;
      align-items: flex-end;
      margin-bottom: 2px;
    }
    h1 {
      min-width: 50%;
      @include md {
        min-width: 40%;
      }
    }
  }
  &.leaderboard {
    padding: 0;
    margin-bottom: 25px;
    border-radius: 16px 16px 0 0;
    @include md {
      border-radius: 16px;
    }
  }
  :global {
    .center {
      overflow-x: auto;
    }
    .lead-grid {
      grid-template-columns: 30px 17px minmax(140px, 1fr) 178px 60px 30px;
      min-width: 530px;
      @include md {
        min-width: 925px;
        grid-template-columns: 30px minmax(200px, 1fr) 252px 82px 55px 158px;
      }
    }
    .titles {
      padding: 8px 16px 2px;
      @include md {
        padding: 6px 26px 6px 21px;
      }
    }
  }
}

.miniEditBtn {
  border: none;
  outline: none;
  background-color: transparent;
}

.deleteBtn {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 0;
}
