@import "../../global/vars.scss";

.header {
  padding: 16px;
  z-index: 9;

  @include md {
    padding: 23px 24px 14px;
  }

  .up {
    display: flex;
    justify-content: space-between;

    & .radioButtons {
      display: flex;
      gap: 1rem;

      label {
        font-weight: 300;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        margin: 0 10px 0 0;

        font: inherit;
        color: currentColor;
        width: 16px;
        height: 16px;
        border: 1.5px solid #8C6CED;
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        cursor: pointer;
      }

      input[type="radio"]::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        /* Windows High Contrast Mode */
        background-color: #8C6CED;
      }

      input[type="radio"]:checked::before {
        transform: scale(1);
      }

    }

    :global {
      h4.boxTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
      }
    }
  }
}

.wrapper {
  padding: 0 8px 16px;
  max-height: 430px;
  height: 100%;
  overflow-y: auto;

  @include md {
    max-height: 430px;
    margin-right: 17px;
    padding: 0 17px 0 20px;
    margin-bottom: 13px;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 25px;
    }
    &::-webkit-scrollbar-track {
      border-right: 2px solid #fff;
      border-left: 2px solid #fff;
      background: #e2e8f0;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      background: #8c6ced;
      cursor: pointer;
    }
  }
}

.badgeContainer {
  display: none;

  & > div {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include sm {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    gap: 5px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 3px;
    }
  }
}

.item {
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  position: relative;
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 15px;
  }

  @include md {
    margin-bottom: 11px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  & .line {
    display: block;
    position: absolute;
    width: 5px;
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    @include md {
      width: 6px;
    }
  }

  .top {
    padding: 12px;
    border-bottom: 1px solid #e7e7e7;

    @include md {
      padding: 16px 22px 12px
    }

    & .title {
      display: flex;
      justify-content: space-between;

      & .iconStar {
        transition: 300ms ease-in-out;

        &:hover {
          & svg {
            path {
              fill: #FFDA18;
              stroke: #FFDA18;
            }
          }
        }

        & svg {
          transition: 500ms ease-in-out;

          path {
            transition: 500ms ease-in-out;
            fill: #fff;
            stroke: #FFDA18;
            stroke-width: 1;
          }
        }

        &__active {
          & svg {
            path {
              fill: #FFDA18;
              stroke: #FFDA18;
            }
          }
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }

    p {
      &:hover {
        color: $purple;
        cursor: pointer;
      }

      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      margin-top: 0;
      margin-bottom: 8px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: rgba(93, 96, 107, 0.51);
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      @include md {
        -webkit-line-clamp: 2;
      }
    }
  }

  .bottom {
    padding: 10px calc(6px + 1rem) 10px calc(6px + 1rem);
    display: flex;
    align-items: center;

    &__priority {
      margin-right: 1.375rem;
    }
  }
}

.dateContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #c8ccdd;

  @include md {
    font-weight: 400;
  }
}

.checkMarkContainer {
  position: absolute;
  right: 11px;

  &__checkMark {;
    background: none;
    border: none;
    transition: 300ms ease-in-out;
    opacity: 0.5;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      opacity: 1;
    }
  }

  &__hint {
    position: absolute;
    width: 133px;
    background-color: $darkGrey;
    border-radius: 8px;
    color: $white;
    padding: 16px;
    top: 0;
    left: 50%;
    transform: translate(-100%, 20%) scale(0.5);
    opacity: 0;
    transition: 300ms ease-in-out;
    z-index: 10;
    pointer-events: none;

    & > p {
      position: relative;
      margin: 0;
      z-index: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.36;
    }

    &::before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      background-color: #373D59;
      left: 50%;
      top: 0;
      z-index: 0;
      transform: translate(85%, -5px) rotate(45deg);
    }
  }

  &:hover {
    .checkMarkContainer__hint {
      opacity: 1;
      transform: translate(-80%, 45px) scale(1);
      pointer-events: all;
    }
  }
}


.projectName {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-right: 1.25rem;

  @include md {
    font-size: 16px;
    line-height: 22px;
  }
}

.seeMore {
  position: relative;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #8c6ced;
  display: flex;
  align-items: center;
  justify-content: center;
  @include md {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    position: relative;
    margin-left: 12px;
    width: 6px;
    height: 10px;
    min-width: 6px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%238C6CED' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }
}
