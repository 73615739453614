@import "../../global/vars.scss";
@import "../../global/interactions.scss";

.right,
.left,
.bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.left {
  gap: 19px;

  @include lg {
    flex: 1;
  }

  & .top {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
}

.right {
  @include xl {
    flex-direction: column;
    justify-content: space-between;
    width: 80px;
  }
}

.bottom {
  @include xl {
    order: 3;
    flex: 1;
    justify-content: flex-start;
    gap: 20px;
  }

  & > div {
    display: flex;
    align-items: center;
    @include xl {
      width: auto;
    }
  }
}

.container {
  position: relative;
  border-radius: 16px;
  background-color: $white;
  margin-top: 16px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  @include cardBoxShadow;

  @include xl {
    flex-wrap: nowrap;
    margin-top: 30px;
    &[class*="headerCard"] {
      .badgeContainer {
        top: 5px;
        //left: 85px;
      }
    }
  }

  &:hover {
    @include cardHover;
    cursor: pointer;
  }
}

.badgeContainer {
  .badge {
    padding: 0px 12px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffe8f2;
    border-radius: 20px;
    color: #f4257c;
    font-weight: 400;
    font-size: 12px;
  }
}

.btnGroup {
  order: 2;
  width: 35%;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  @include xl {
    order: 4;
    margin-bottom: 0;
    width: 100%;
    margin: 0;
  }
}

.avatarContainer {
  order: 3;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50%;
  background: #f6f6f9;
  @include xl {
    width: 44px;
    height: 44px;
    order: 1;
    margin-right: 16px;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}

.title {
  order: 4;
  flex: 1;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    color: $purple;
    cursor: pointer;
  }

  @include xl {
    order: 2;
    font-size: 20px;
    line-height: 27px;
  }
}

.replies {
  order: 5;

  @include xl {
    order: 1;
  }

  span {
    font-size: 14px;

    &:first-child {
      font-weight: 700;
    }

    &:nth-child(2) {
      margin-left: 5px;
      font-weight: 300;
    }
  }
}

.byWhom {
  order: 7;
  flex-grow: 1;

  @include xl {
    order: 2;
  }

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  span {
    font-size: 14px;

    &:nth-last-child(n-1) {
      font-weight: 300;
      margin-left: 8px;
    }

    &:last-child {
      margin-left: 3px;
      font-weight: 700;
      color: #9b7efa;
    }
  }
}

.participants {
  order: 6;
  margin-bottom: 10px;
  width: 100%;
  @include xs {
    width: calc(60% - 6px);
    justify-content: flex-end;
    margin-bottom: 16px;
  }
  @include xl {
    margin-bottom: 0;
    order: 3;
  }

  span {
    font-size: 14px;

    &:first-child {
      font-weight: 700;
    }

    &:nth-child(2) {
      margin-left: 3px;
      font-weight: 300;
    }
  }

  div {
    width: 46px;
    position: relative;
    height: 20px;

    img {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      border: 1px solid $white;

      &:first-child {
        left: 0;
      }

      &:nth-child(2) {
        left: 50%;
        transform: translateX(-50%);
      }

      &:last-child {
        right: 0;
      }
    }
  }
}

.ago {
  order: 8;
  display: flex;

  span {
    font-size: 14px;
    color: #a5a5a5;

    & .days {
      font-weight: 700 !important;
    }

    &:last-child {
      font-weight: 300;
    }
  }
}

.adminBtn {
  //z-index: 99;
  display: flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  @include xl {
    margin: 0 15px 0 0;
  }
}

.modalWrapper {
  width: 100%;
  @include md {
    min-width: 456px;
  }
}

.fileZone {
  &:hover {
    cursor: pointer;
  }

  width: 100%;
  min-height: 80px;
  position: relative;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238360E6FF' stroke-width='2' stroke-dasharray='20' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: transparent;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.attachments {
  margin-bottom: 16px;
  display: flex;
  row-gap: 12px;
  column-gap: 12px;
  flex-wrap: wrap;

  .loadingContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .info {
      display: flex;
      justify-content: space-between;

      .fileInfo {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        & > img {
          margin-right: 12px;
        }

        & > span {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .empty {
      position: relative;
      width: 100%;
      height: 3px;
      border-radius: 16px;
      background-color: #c4c4c4;

      & > span {
        color: $purple;
        position: absolute;
        right: 0;
        top: -25px;
      }

      .loaded {
        height: 100%;
        background-color: $purple;
        border-radius: 16px;
      }
    }
  }

  .imgContainer {
    display: flex;
    align-items: center;

    &:hover {
      .deleteContainer {
        display: block;
      }
    }

    position: relative;
    border-radius: 15px;
    overflow: hidden;

    img {
      width: 138px;
      height: 127px;
      object-fit: cover;
      object-position: center;
      border-radius: 15px;
    }

    .deleteContainer {
      display: none;
      position: absolute;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      background: rgba(255, 255, 255, 0.8);
      top: 10px;
      right: 10px;

      img {
        position: absolute;
        height: 12px;
        width: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .description {
      display: flex;
      position: absolute;
      bottom: 0;
      height: 30px;
      width: 100%;
      background-color: #d6cbff;
      padding: 8px 12px;
      text-align: center;

      span {
        -webkit-box-orient: vertical;
        font-weight: 400;
        font-size: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.modalTitleContainer {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include md {
    padding: 20px;
  }

  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $darkGrey;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 32px 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 32px 20px 32px;
  }
}

.modal {
  width: 100%;
  @include md {
    max-width: 456px !important;
  }
}

.textInput {
  font-weight: 300;
  font-size: 14px;
}

.select {
  width: 100%;
  height: 47px;

  > div {
    width: 100%;

    > div:nth-child(3) {
      height: 47px;
      padding: 0 16px;
      width: 100%;
    }
  }
}

.createBtn {
  width: 100%;
  align-self: flex-end;
  margin-top: 30px;
  border-radius: 15px;
  @include md {
    width: 162px;
  }
}

.btnContainer {
  display: flex;
  column-gap: 26px;
}

.deleteBtnModal {
  width: 120px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #f85c56;

    img {
      filter: none;
    }
  }
}

.deleteModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.deleteModalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.deleteModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 40px;
  @include lg {
    padding: 0 25px 42px;
  }

  & > img {
    width: 45px;
    display: block;
    margin-bottom: 18px;
    @include lg {
      margin-bottom: 22px;
    }
  }

  & > p {
    margin: 0;
    max-width: 250px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
    @include lg {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 22px;
    }
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
}

.modalDelete {
  max-width: 576px !important;
}
