@import '../../global/vars.scss';
@import '../../global/interactions.scss';

.card {
  &:hover, &:active {
    @include cardHover;
    cursor: grab;
  }
  @include cardBoxShadow;
  height: 100%;
  background-color: $white;
  border-radius: 6px 6px 10px 10px;
  padding: 24px 20px 20px;
  border-top: 10px solid #8C6CED;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 25px 15px;
  }
  @include md2 {
    padding: 25px 20px 27px;
  }

  .top {
    @include sm {
      flex: 1;
    }
  }
}

.title {
  margin: 24px 0 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  max-width: 100%;

  a {
    color: #000;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: inherit;
    display: inline-block;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      color: $purple;
    }
  }


}

.description {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(93, 96, 107, 0.51);
  margin-bottom: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  height: 76px;
  @include md {
    margin-bottom: 20px;
  }
}

.select {
  width: 150px;

  :global {
    .b__control {
      padding: 7px 14px;
    }

    .b__option > div > div {
      padding: 4px 14px 7px;
    }

    .b__menu {
      margin-top: -4px;
    }
  }
}

.badgeContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 7px;
  margin-bottom: 25px;
  height: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    height: 2px;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background: #e2e8f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #8c6ced;
    cursor: pointer;
  }

  @include sm {
    margin-bottom: 0;
  }
  @include md {
    max-width: 355px;
  }
}

.newBadge {
  background: none;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  border: none;
  color: #C8CCDD;
  font-weight: 600;
  transition: all 0.3s linear;
  font-size: 19px;

  &:hover {
    color: #8C6CED;
    background: none;
  }
}

.group {
  display: flex;
  align-items: center;

  & .date {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  & .category {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 0 6px;
    border-radius: 6px;
    height: 1.4375rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #fff;
  }

  span {
    padding-left: 12px;
    font-weight: 500;
    font-size: 14px;
    color: $lightGrey2;
    line-height: 19px;
  }
}
