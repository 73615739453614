@import "../vars";

.navbar {
  display: grid;
  grid-template-rows: 80px 10px;
  grid-template-columns: 180px 1fr;
  grid-template-areas:
    "logo    user"
    "   center center";
  @include md {
    min-height: 80px;
    grid-template-columns: 218px 3fr max-content;
    grid-template-rows: 1fr;
    grid-template-areas: "logo  center user";
  }

  align-items: stretch;
  div {
    display: flex;
    align-items: center;
    @include md {
      justify-content: center;
    }
  }
  :global {
    .grid {
      &-logo {
        padding: 0 20px;
        grid-area: logo;
        background-color: #fff;
        img {
          height: 40px;
          @include md {
            margin-top: 10px;
            height: 45px;
          }
        }
      }
      &-center {
        padding: 0 20px;
        grid-area: center;
        justify-content: space-between;
        width: 100%;
        @include md {
          padding-right: 20px;
          background-color: #fff;
        }
      }
      &-user {
        padding: 0 20px 0px 0px;
        @include md {
          padding-right: 70px;
          padding: 0 20px
        }
        justify-content: flex-end;
        grid-area: user;
        background-color: #fff;
        a{
          text-decoration: none;
          color: $darkGrey;
          display: flex;
          align-items: center;
          .text {
            margin-right: 10px;
            font-size: 1rem;
            @include md {
              display: block;
            }
          }
          img {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            object-fit: cover;
          }
        }
        
      }
    }
  }
}

.search {
  margin-right: 15px;
  @include lg {
    font-size: 16px;
    margin-left: 35px;
    margin-right: 21%;
  }
}
