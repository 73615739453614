.buttonClass {
  background-color: transparent !important;
  border: none !important;
  &:hover {
    background-color: initial !important;
  }
}

.selected-flag {
  &:hover {
    background-color: initial !important;
  }
}

.inputClass {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #5d606b !important;
  height: 47px !important;
  padding-left: 53px !important;
}

.dropdownClass{
  max-height: 140px!important;;
  border-radius: 8px!important;;
  box-shadow: 0px 4px 20px 0px #8729FB12!important;

}