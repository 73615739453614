@import '../vars.scss';
$closeDelay:0.5s;
.sidebar{
    transition-timing-function: ease-in;
    transition-duration:$closeDelay;
    background-color: #fff;
    width: 100%;
    flex-shrink: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    @include md {
        width: 218px;
        position: relative;
        top: 0;
    }
    &-wrapper{
        position: sticky;
        top: 0;
    }
    &LogOut{
        &:hover{
            background-color: $error;
        }

        @include md{
            position: fixed;
            bottom: 34px;
            left: 33px;
            padding: 10px 2px;
            transition: left 0s $closeDelay;
            img{
                display: block;
                margin-right: 9px;
                transition: margin $closeDelay $closeDelay;
            }
            span{
                padding-right: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: $lightGrey;
                transition : font-size 0s $closeDelay, opacity $closeDelay $closeDelay, padding 0s $closeDelay;
                opacity: 1;
            }
            &:hover{
                span{
                    color: #fff;
                }
                img{
                    filter: brightness(4);
                }
            }
        }
    }
    &-min{
        display: none;
    }
    .navmenu{
        width: 100%;
        transition: $closeDelay;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 80px;
        padding: 17px 28px 19px;
        @include md{
            flex-direction: column;
            align-items: flex-start;
            padding: 34px;
            max-height: none;
        }
    }
    @include md{
        &-min{
            display: block;
            font-size: 0;
            position: absolute;
            left: 100%  ;
            height: 50px;
            width: 50px;
            background-color:#eff2f9;
            border-radius: 50%;
            top:max(50vh , 100%);
            transform: translate(-50%, -50%);
            svg{
                transform: rotate(180deg);
            }
        }
        .navlink {
            .text {
               transition : font-size 0s $closeDelay  , opacity $closeDelay $closeDelay ;
                opacity: 1;
            }
        }
        &.minimized {
            width: 110px;
            .sidebarLogOut{
                left: 19px;
                span{
                    transition :  font-size 0s 0s ;
                    font-size: 0;
                    opacity: 0;
                    padding-right: 0;
                }
                img{
                    margin-right: 0;
                }
            }
            .navmenu{
                margin-left: 5px;
            }
            .navlink {
                .text {
                    transition :  font-size 0s 0s ;
                    font-size: 0;
                    opacity: 0;
                }
            }
            .sidebar-min{
                svg{
                    transform: rotate(0deg);
                }
            }
        }
    }

    .ver {
        font-size: 0.75rem;
        display: block;
        width: 100%;
        text-align: left;
        color: #C8CCDD;
        position: fixed;
        bottom: 8px;
        left: 8px;
    }

    & .sidebarLogOut {
        transition: 250ms background-color;

        & > div > span {
            transition: 250ms color;
        }
    }

}
