@import "../../global/vars.scss";

.container {
  padding: 0 !important;
  max-height: 250px;
  @include xl {
    width: calc(40% - 10px) !important;
    min-width: calc(40% - 10px)!important;
  }
  :global {
    .analog-clock {
      .react-clock__face {
        border-color: transparent;
      }
      .react-clock__mark {
        &.react-clock__minute-mark {
          .react-clock__mark__body {
            opacity: 0.7;
            height: 4px;
            width: 0.5px !important;
          }
        }
        &.react-clock__hour-mark {
          .react-clock__mark__body {
            height: 7px;
            width: 1px !important;
          }
        }
      }
    }
    .customCheckbox{
      flex: 1;
      overflow: hidden;
      margin-right: 10px;
      label span,
      input:checked + span{
        margin-right: 13px;
      }
      label span{
        min-width: 24px;
      }
    }
  }
}

.header {
  padding: 17px 14px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  z-index: 9;
  @include md {
    padding: 20px 24px 0;
  }
  .up {
    display: flex;
    justify-content: space-between;
    @include md {
      margin-bottom: 3px;
    }
    h4{
      font-size: 20px;
      line-height: 30px;
      @include lg{
        font-size: 20px!important;
      }
    }
  }
}
.timeZones {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: flex-start;
  scrollbar-width: none;
  gap: 20px;
  padding: 0 16px 10px;
  margin-bottom: 6px;
  @include md {
    gap: 30px;
    padding: 0 25px 15px;
    margin: 0 0 5px;
  }
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    border-top: 1.5px solid #fff;
    border-bottom: 1.5px solid #fff;
    background: #E2E8F0;
  }
  &::-webkit-scrollbar-thumb {
    background: #C8CCDD;
    border-radius: 25px;
  }
}

.saveContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  & > img {
    height: 15px;
    width: 15px;
    margin-right: 10px;
  }
  & > span {
    font-weight: 500;
    font-size: 16px;
    color: $purple;
  }
}

.editTimeZones {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
}

.search{
  &>input{
    border: none;
    border-bottom: 1px solid #C8CCDD;
    border-radius: 0;
    background-color: transparent !important;
    padding: 6px 10px 7px 37px;
    height: 33px;
    font-size: 16px;
  }
}

.timeZoneList {
  display: flex;
  flex-direction: column;
  max-height: 140px;
  margin-top: 6px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.timeZoneItem {
  padding: 14px 9px 14px 4px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(196, 196, 196, 0.38);
  &:last-child {
    border-bottom: none;
  }
  label {
    font-weight: 500 !important;
    font-size: 16px !important;
  }
  &.active {
    label {
      color: $purple;
    }
  }
}

.currentTime {
  font-weight: 400;
  font-size: 16px;
  color: #c8ccdd;
}
