@import "../../global/vars.scss";

.select {
  text-align: left;
  font-weight: 400;
  outline: none;
  width: 100%;
  &:focus {
    outline: none;
  }
  &:hover {
    :global {
      .b__control {
        border-color: rgba(0, 0, 0, 0.1);
        &--menu-is-open {
          border-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  & > div {
    min-height: 1px;
    & > div {
      padding: 0 !important;
    }
  }

  :global {
    .b__single-value {
      margin: 0;
    }
    .b__control {
      min-height: 20px;
      padding: 12px 16px;
      width: 100%;
      line-height: 1;
      border: none;
      outline: none;
      box-shadow: none;
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      .b__value-container {
        padding: 6px 5px 2px 12px;
      }
      &--is-focused {
        border-color: rgba(0, 0, 0, 0.1) !important;
      }
      &--menu-is-open {
        box-shadow: none;
        border-radius: 6px 6px 0 0;
        border-bottom-color: transparent !important;
        .b__indicators {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    .b__menu {
      margin: 0;
      border-radius: 0 0 6px 6px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: none;
      overflow: hidden;
      margin-top: -9px;
    }
    .b__option,
    .b__value-container {
      cursor: pointer;
      min-width: 77px;
      padding: 5px 5px 5px 15px;
      background-color: #fff;
      font-size: 14px;
      line-height: 20px;
    }
    .b__option {
      &:hover {
        font-weight: 600;
      }
      &:active {
        font-weight: 500;
        background-color: transparent;
      }
    }
    .b__option--is-selected {
      display: none;
    }
    .b__indicators {
      svg {
        min-width: 11px;
        width: 11px;
        path {
          stroke: #818181;
        }
      }
    }
  }
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
}

.optionButton {
  background: transparent;
  color: #8360e6;
  width: 100%;
  text-align: center;
  border: none;
  padding: 14px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  &:hover {
    background: rgba(131, 96, 230, 0.11);
  }
}

.option {
  text-align: left;
  padding: 4px 16px 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #373d59;
  margin: 0;
  width: 100%;
  display: flex;
}

.optionContainer {
  display: flex;
  height: 30px;
  align-items: center;

  & .icon:first-child,.circle:first-child {
    margin-left: 16px;
  }

  & .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  &:hover {
    background: rgba(131, 96, 230, 0.11);
  }
}

.selectedOptionContainer {
  display: flex;
  align-items: center;

  & .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .circle:first-child {
    margin-right: 16px;
  }
}

.optionWrapper {
  padding: 0 !important;
}

.indicatorBackground {
  height: 100%;
  width: 43px !important;
  background: rgba(240, 236, 255, 0.32);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.error {
  color: red !important;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red;
  padding-top: 8px;
  display: block;
  text-align: right;
}
