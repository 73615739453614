@import '../vars.scss';

.selectBadge {
    font-weight: 400;
    outline: none;
    width: 100%;

    &:focus {
        outline: none;
    }

    &:hover {
        :global {
            .b__control {
                border-color: rgba(0, 0, 0, 0.1);

                &--menu-is-open {
                    border-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    &>div {
        min-height: 1px;

        &>div {
            padding: 0 !important;
        }
    }

    :global {
        .b__single-value {
            margin: 0;
        }

        .b__control {
            min-height: 20px;
            padding: 8px 11px 8px 16px;
            width: 100%;
            line-height: 1;
            border: none;
            outline: none;
            box-shadow: none;
            background: none;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 20px;

            .b__value-container {
                padding: 6px 5px 2px 12px;
            }

            &--is-focused {
                border-color: rgba(0, 0, 0, 0.1) !important;
            }

            &--menu-is-open {
                box-shadow: none;
                border-radius: 20px 20px 0 0;
                border-bottom-color: transparent !important;

                .b__indicators {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .b__menu {
            margin: 0;
            border-radius: 0 0 20px 20px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: none;
            overflow: hidden;
            margin-top: -4px;
            &-list{
                max-height: 150px;
            }
        }

        .b__option,
        .b__value-container {
            cursor: pointer;
            padding:0;
            background-color: #fff;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: left;
        }

        .b__option {
            &:hover {
                font-weight: 600;
            }

            &:active {
                font-weight: 500;
                background-color: transparent;
            }
        }

        .b__option--is-selected {
            display: none;
        }

        .b__indicators {
            svg {
                min-width: 11px;
                width: 11px;

                path {
                    stroke: #818181;
                }
            }
        }
    }
}

.option {
    padding: 0 5px 0 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #373d59;
    margin: 0;
    width: 100%;
}

.optionContainer {
    display: flex;
    height: 30px;
    align-items: center;

    img:first-child {
        margin-left: 16px;
    }

    &:hover {
        background: rgba(131, 96, 230, 0.11);
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
}

.optionButton {
    background: transparent;
    color: #8360e6;
    width: 100%;
    text-align: center;
    border: none;
    padding: 14px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    &:hover {
        background: rgba(131, 96, 230, 0.11);
    }
}