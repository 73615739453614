@import "../../global/vars.scss";

.body {
  width: 100%;
  background-color: $white;
  padding: 16px 12px 20px;
  height: 100%;
  border-radius: 10px;
  @include lg {
    border-radius: 16px;
    padding: 15px 20px 20px;
  }

  .header {
    .headerCard {
      margin-top: 0;
      margin-bottom: 10px;
      padding: 15px 20px;
      @include lg {
        margin-bottom: 16px;
        padding: 32px 24px 15px;
      }
    }

    .badgeContainer {
      @include lg {
        top: 5px;
        left: 85px;
      }
    }
  }

  .asnwerContainer {
    display: flex;
    flex-direction: column;
  }
}

.chatBtn {
  white-space: nowrap;
  border-radius: 15px !important;

  img {
    margin-right: 12px;
    filter: brightness(3);
  }

  &:hover {
    img {
      filter: none;
    }
  }

  @include xl {
    width: 174px;
  }
}

.top {
  display: flex;
  width: 100%;
  height: 40px;
  column-gap: 16px;
}

.modalWrapper {
  width: 80vw;
  max-width: 860px;
}

.modalAnswer {
  max-width: 860px !important;
}

.modalTitleContainer {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @include md {
    padding: 20px;
  }

  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .left {
    display: flex;
    flex-direction: column;

    .badge {
      border-radius: 20px;
      background-color: #f0ecff;
      color: $purple;
      width: max-content;
      padding: 4px 12px;
      margin-bottom: 8px;
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $darkGrey;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 32px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 32px 20px 20px;
  }
}

.textInput {
  label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 16px !important;
  }

  margin-bottom: 20px !important;
}

.fileZone {
  &:hover {
    cursor: pointer;
  }

  width: 100%;
  min-height: 80px;
  position: relative;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238360E6FF' stroke-width='2' stroke-dasharray='20' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: transparent;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.saveBtn {
  align-self: flex-end;
  width: 100%;
  height: 46px;
  @include md {
    width: 162px;
  }
}

.attachments {
  margin-bottom: 16px;
  display: flex;
  row-gap: 12px;
  column-gap: 12px;
  flex-wrap: wrap;

  .loadingContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .info {
      display: flex;
      justify-content: space-between;

      .fileInfo {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        & > img {
          margin-right: 12px;
        }

        & > span {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .empty {
      position: relative;
      width: 100%;
      height: 3px;
      border-radius: 16px;
      background-color: #c4c4c4;

      & > span {
        color: $purple;
        position: absolute;
        right: 0;
        top: -25px;
      }

      .loaded {
        height: 100%;
        background-color: $purple;
        border-radius: 16px;
      }
    }
  }

  .imgContainer {
    display: flex;
    align-items: center;

    &:hover {
      .deleteContainer {
        display: block;
      }
    }

    position: relative;
    border-radius: 15px;
    overflow: hidden;

    img {
      width: 138px;
      height: 127px;
      object-fit: cover;
      object-position: center;
      border-radius: 15px;
    }

    .deleteContainer {
      display: none;
      position: absolute;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      background: rgba(255, 255, 255, 0.8);
      top: 10px;
      right: 10px;

      img {
        position: absolute;
        height: 12px;
        width: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .description {
      display: flex;
      position: absolute;
      bottom: 0;
      height: 30px;
      width: 100%;
      background-color: #d6cbff;
      padding: 8px 12px;
      text-align: center;

      span {
        height: max-content;
        font-weight: 400;
        font-size: 10px;
      }
    }
  }
}

.breadCrumb {
  margin-bottom: 15px;
  @include lg {
    position: absolute;
    top: -50px;
  }
}
