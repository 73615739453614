.backCard{
    @include md{
        padding: 18px;
        background: #F6F6F9;
        border-radius: 33px;
    }
}
.border-xs .backCard{
    padding: 8px;
    border-radius: 20px;
    background: #F6F6F9;
    @include md{padding: 18px;}
}
.dashboard-border{
    margin: 0 -8px;
    @include lg{
        margin: 23px 0 0;
    }
}
.border{
    &.border-xs{
        overflow: hidden;
        background: linear-gradient(266deg, rgba(255, 255, 255, 0) 2.25%, #ffffff 38.75%, #ffffff 44.91%, #ffffff 49.73%, rgba(255, 255, 255, 0) 98.48%);
        padding: 2px;
        border-radius: 20px;
        @include md{
            border-radius: 33px;
        }
    }
    @include md{
        padding: 2px;
        border-radius: 33px;
        background: linear-gradient(215deg, rgba(255, 255, 255, 0) 2.25%, #ffffff 38.75%, #ffffff 44.91%, #ffffff 49.73%, rgba(255, 255, 255, 0) 98.48%);
    }
}
.whiteBg{
    padding: 24px 16px;
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    @include md{
        border-radius: 16px;
        box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    }
    @include xl{
        padding: 20px 20px;
    }
}
.dashboardBlock{
    gap: 20px
}
.apexcharts-tooltip-series-group{
    justify-content: center!important;
    .apexcharts-tooltip-text-y-value{margin: 0;}
}
