@import '../../global/vars.scss';
.container {
  position: relative;
  max-width: 100vw;
  display: flex;
  column-gap: 12px;
  height: 100%;
  overflow-x: auto;
  align-items: flex-start;
  @include md{
    margin-right: -53px;
    padding-right: 53px;
    margin-bottom: -20px;
    padding-bottom: 20px;
  }
}

.swiperSlide{
  display: flex;
  justify-content: center;
}
