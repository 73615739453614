@import "../../global/vars.scss";
@import "../../global/interactions.scss";

.title {
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #373d59;
    @include md {
      font-size: 32px;
    }
  }
}
.colorModal {
  max-width: 658px;
}
.container {
  min-height: 60vh;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  background-color: $white;
  border-radius: 16px;

  @include md {
    padding: 20px;
  }
}
.top {
  display: flex;
  align-items: center;
  margin: 16px 0 23px 3px;
  @include md {
    flex-direction: column;
    align-items: flex-start;
  }
  @include lg {
    margin: 3px 0 26px 0px;
  }
  .backBtn {
    @include animateBack;
  }
}
.left {
  flex: 1;
  padding-bottom: 13px;
  @include lg {
    padding-right: 16px;
    padding-bottom: 0;
  }
  @include xl {
    padding-right: 7rem;
  }
  .formControls {
    margin-bottom: 11px;
    @include md {
      margin-bottom: 34px;
    }
  }
}
.right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  @include lg {
    width: 349px;
    border-top: none;
  }
  &Form {
    padding-top: 32px;
    @include lg {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      padding: 4rem 0px 7rem 20px;
    }
    @include xl {
      padding: 66px 27px 6.7rem 40px;
    }
  }
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 44px 0 0 0;
  @include lg {
    padding: 2rem 0 0 20px;
  }
  @include xl {
    padding: 40px 27px 0 20px;
  }
}
.btn {
  &Create {
    width: 100%;
    @include md {
      width: 162px;
    }
  }
  &Cancel {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
}
.select {
  & > div {
    width: 100%;
    max-width: 170px;
    & > div {
      min-height: 38px;
    }
    @include xs {
      max-width: 203px;
    }
  }
  label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
  }
  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    width: 100%;
    max-width: 170px;
    padding: 2px 16px 3px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 11px 15px 11px;
    @include xs {
      max-width: 203px;
    }
  }
  label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
  }
  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.checkBox {
  margin-top: 29px;
  @include xl {
    margin-top: 20px;
  }
}

.rightButtonGroup {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  gap: 18px;
  align-items: center;
  @include md {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.miniTitle {
  display: block;
  color: #a0aec0;
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;
  margin-bottom: 16px;
  @include md {
    margin-bottom: 24px;
  }
}

.helperInfo {
  color: #c7c8cc;
  font-weight: 400;
  font-size: 12px;
  display: block;
  margin-top: 8px;
  line-height: 16px;
}

.modalTitleContainer {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include md {
    padding: 24px 37px 25px;
  }
  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $darkGrey;
      @include md {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 32px 16px 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 32px 36px 27px;
  }
}

.modalButton {
  margin-top: 47px;
  align-self: flex-end;
  width: 100%;
  @include md {
    width: 222px;
  }
}

.colorsContainer {
  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    margin-bottom: 8px;
    @include md {
      margin-bottom: 19px;
    }
  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.sphere {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.add {
    border: 1px solid #acacac;
    box-sizing: border-box;
  }
}

.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red;
  padding-top: 8px;
  &.validationRight {
    display: block;
    text-align: right;
  }
}

.categoryInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > input {
    @include xs {
      max-width: 203px;
    }
    max-width: 170px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > input {
    @include xs {
      max-width: 203px;
    }
    max-width: 170px;
  }
}

.smallForm {
  span {
    display: block;
    text-align: right;
  }
}
