@import '../vars.scss';

.container{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E2E8F0;
    border-radius: 15px;
    display: flex;
}

.tab{
    background: transparent;
    color: $lightGrey2;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    padding: 0px 25px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 16px;
    border: 2px solid #fff;
    transition: all 0.25s;
}

.active{
    background: $purple;
    color: $white;
    border-color: $purple;
    &:hover{
        background: $purple;
        color: $white;
        border-color: $purple;
    }
}
