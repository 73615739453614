@import "../global/vars.scss";

.title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-area: 1 / 1 / 2 / 2;
  flex-direction: row-reverse;
  @include lg {
    grid-area: 1 / 1 / 2 / 3;
    justify-content: space-between;
    flex-direction: row;
  }

  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 19px;
    line-height: 150%;
    color: #373d59;
    @include md {
      font-size: 32px;
    }
  }
}

.arrow {
  &:hover {
    cursor: pointer;
  }
}

.settingsBtn {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid #8c6ced;
  color: #8c6ced;
  padding: 0px;
  margin-right: 10px;
  width: 25px;
  height: 25px;

  span {
    display: none;
  }

  &:hover {
    img {
      filter: brightness(3);
    }
  }

  @include xs {
    margin-right: 20px;
    width: 35px;
    height: 35px;
    padding: 5px;
  }
  @include xl {
    height: auto;
    width: auto;
    padding: 0 16px;
    margin-right: 10px;
    span {
      display: block;
    }
    img {
      margin-right: 10px;
    }
  }
}

.results {
  grid-area: 2 / 1 / 3 / 3;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;

  b {
    font-weight: 600;
  }
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
  @include lg {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 240px 1fr;
    margin-bottom: 40px;
    padding: 10px 2px;
  }
  @include xl {
    grid-template-columns: 307px 1fr;
  }

  &.wrapperOne {
    grid-row-gap: 7px;

    @include lg {
      grid-row-gap: 20px;
    }

    & .forumsBody {
      @include lg {
        margin-top: 20px;
      }

      grid-area: 3/1/4/3;
    }
    .title {
      grid-area: 1/1/2/3;
    }
  }
}

.backBtn {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  gap: 8px
}

.categoryBar {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  @include lg {
    grid-area: 2 / 1 / 3 / 2;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $white;
    align-self: start;
    border-radius: 16px;
    gap: 12px;
    padding-bottom: 24px;
    & > span {
      font-weight: 600;
      font-size: 24px;
    }
  }

  :global {
    .ActionButtonsGroup {
      button {
        margin: 0;
      }
    }
  }

  .cateogoryTitle {
    padding: 20px;
    padding-bottom: 0;

    & > span {
      font-weight: 600;
      font-size: 24px;
    }

    display: flex;
    justify-content: space-between;
  }

  .categories {
    .checkBox {
      padding: 8px 20px;
      padding-right: 0;

      &.active {
        label {
          font-weight: 600;

          & > span {
            background-image: none;
          }
        }
      }

      label {
        white-space: inherit;
        word-break: break-all;
        color: #373d59;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        align-items: center;
        @include lg {
          font-size: 1.1rem;
          line-height: 1.5rem;
          align-items: flex-start;
        }
      }
    }
  }
}

.checkBoxWrapper {
  display: flex;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow-x: scroll;
}

.categoryInput {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #c8ccdd;
  border-radius: 0;
}

.checkBoxContainer {
  width: 100%;
  position: relative;

  > img {
    padding-right: 20px;
  }

  &:hover {
    background-color: #f0ecff;

    > span {
      display: block;

      &:hover {
        cursor: default;

        .adminBtnGroup {
          display: flex;
        }
      }
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    display: none;
    font-size: 12px;
    color: $lightGrey2;
    padding-right: 20px;
    padding-left: 10px;
  }

  .adminBtnGroup {
    position: absolute;
    padding: 9.5px 30px;
    border: 1px solid #c8ccdd;
    border-radius: 8px;
    background-color: $white;
    column-gap: 17px;
    bottom: -50%;
    z-index: 1;
    left: 50%;
    display: none;

    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.actionButtonsGroup {
  padding: 0 20px;
}

.forumsBody {
  position: relative;
  grid-area: 3 / 1 / 4 / 3;
  @include lg {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.modalWrapper {
  width: 100%;
  max-width: 633px !important;

  .textArea {
    margin-bottom: 0;

    > label {
      margin-bottom: 7px;
      @include lg {
        margin-bottom: 12px;
      }
    }

    textarea {
      height: 65px;
      padding: 10px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      @include lg {
        height: 100px;
      }
    }
  }
}

.modal {
  width: 100%;
  @include md {
    max-width: 456px !important;
  }
}

.categoryModalWrapper {
  @include md {
    min-width: 456px;
  }
}

.modalTitleContainer {
  padding: 17px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include md {
    padding: 20px;
  }

  button {
    background: none;
    border: none;
    outline: none;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.modalTitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: $darkGrey;
    }
  }
}

.modalDivider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.modalBodyContainer {
  padding: 17px 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include md {
    padding: 25px 20px 32px;
  }
}

.up {
  margin-bottom: 10px;
  @include lg {
    margin-bottom: 24px;
  }
}

.down {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 15px;
  row-gap: 0;
  @include xs {
    flex-direction: row;
  }

  .textInput {
    flex: 1;
    min-width: 40%;
  }
}

.modalSettings {
  max-width: 633px !important;
}

.backBtn {
  span {
    display: block;
  }
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  flex-direction: column;
  @include lg {
    margin-top: 14px;
    align-items: center;
    flex-direction: row;
  }
}

.toggleWrapper {
  display: flex;
  align-items: center;

  > span {
    font-weight: 500;
    font-size: 14px;
  }
}

.modalSaveBtn {
  width: 100%;
  height: 45px;
  margin-top: 20px;
  @include lg {
    width: 160px;
    height: 40px;
  }
}

.createBtn {
  width: 162px;
  height: 46px;
  align-self: flex-end;
}

.textInputCategory {
  margin-bottom: 44px !important;
}

.dropdown {
  position: relative;

  .dropownTitle {
    span {
      padding-right: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    color: #7e5ae1;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    transition: width 0.3s linear;
    justify-content: flex-end;
  }

  &Body {
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s linear 0.2s;
  }

  &Bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: none;
    transition: background 0.3s linear 0.2s;
  }

  &Wrapper {
    transition: width 0.3s linear;
  }

  &.open {
    .dropdownBg {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      rgba(214, 203, 255, 0.3);
      backdrop-filter: blur(2px);
      z-index: 20;
    }

    .dropdownWrapper {
      position: absolute;
      width: 237px;
      background: #fff;
      border-radius: 8px;
      z-index: 21;
      top: -20px;
      right: 0px;
    }

    .dropownTitle {
      justify-content: space-between;
      width: 100%;
      padding: 20px;
    }

    .dropdownBody {
      height: auto;
      opacity: 1;

      .categories {
        padding-bottom: 20px;
      }
    }
  }

  .dropdownBtnAdd {
    background: rgba(131, 96, 230, 0.11);
    color: #8360e6;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 14px 25px 15px;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;

    img {
      width: 10px;
      min-width: 10px;
      height: 10px;
      min-height: 10px;
      display: block;
      margin-right: 13px;
    }
  }
}

.pagination {
  position: fixed;
  bottom: 80px;
  left: 0;
  height: 54px;
  background-color: $white;
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #E2E8F0;
  @include md {
    bottom: 0;
    justify-content: flex-end;
  }
  @include lg {
    padding: 16px 54px;
  }

  .paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    @include md {
      width: calc(100% - 218px);
    }
    @include lg {
      justify-content: space-between;
      width: calc(100% - 220px - 54px - 218px);
      padding-right: 54px;
    }
    @include xl {
      width: calc(100% - 218px - 56px - 307px);
    }
  }

  .results {
    display: flex;
    margin-left: 10%;

    span {
      font-size: 16px;

      &:first-child {
        font-weight: 700;
        display: block;
        margin-right: 6px;
      }
    }
  }
}

.mobileCheckBoxLeft {
  flex: 1;
  display: flex;
  align-items: center;
}

.mobileCheckBoxContainer {
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 20px;
  position: relative;

  .checkBox {
    padding: 0 !important;
  }

  .categoryInput {
    width: 100%;
  }
}

.mobileAdminBtnGroup,
.adminBtnGroup {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    background: none;
    border: none;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    border-radius: 0;

    &:hover {
      background: none;
    }
  }
}

.mobileBtnHidd {
  background: none;
  border: none;
  width: auto;
  height: auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translate(0, -50%);

  &:hover {
    background: none;
  }

  img {
    width: 10px;
    height: 10px;
  }
}

.btnContainer {
  display: flex;
  column-gap: 26px;
}

.modalDelete {
  max-width: 576px !important;
}

.deleteBtnModal {
  width: 120px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #f85c56;

    img {
      filter: none;
    }
  }
}

.deleteModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.deleteModalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.deleteModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 40px;
  @include lg {
    padding: 0 25px 42px;
  }

  & > img {
    width: 45px;
    display: block;
    margin-bottom: 18px;
    @include lg {
      margin-bottom: 22px;
    }
  }

  & > p {
    margin: 0;
    max-width: 250px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
    @include lg {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 22px;
    }
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
}

.validationHelper {
  font-weight: 400;
  font-size: 12px;
  color: red;
}
