@import "../../global/vars.scss";

@mixin borderBottom {
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -20%;
    width: 130vw;
    height: 0.5px;
    background-color: #e2e8f0;
    z-index: 1;
  }
}

@mixin flex-aling-center {
  display: flex;
  align-items: center;
}

.campaigns {
  position: relative;
  width: calc(50% - 10px);
  padding: 20px 24px;
  border-radius: 16px;
  background-color: white;
  overflow: hidden;

  @media screen and (max-width: $xl) {
    width: 100%;
  }
}

.top {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  @include borderBottom;

  @media screen and (max-width: $md) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    gap: 15px;
    padding-bottom: 20px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: #373d59;
  }
}

.card {
  position: relative;
  width: 100%;
  height: 112px;
  border: 1px solid #f0f3f7;
  border-radius: 16px;
  overflow: hidden;

  &__top {
    position: relative;
    height: 44px;
    width: 100%;
    @include flex-aling-center;
    @include borderBottom;

    &__title {
      display: flex;
      gap: 10px;
      padding: 13px 33px;
      font-size: 16px;
      font-weight: 600;
      color: #373d59;
      @include flex-aling-center;
    }
  }

  &__metrics {
    display: flex;
    align-items: center;
    height: 66px;
    padding: 0px 33px;
    position: relative;
    width: 100%;

    &__item {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 4px;

      &__name {
        font-weight: 400;
        font-size: 14px;
        color: #a5a5a5;

        @media screen and (max-width: $sm) {
          font-size: 12px;
        }
      }

      &__value {
        font-weight: 700;
        font-size: 18px;

        @media screen and (max-width: $sm) {
          font-size: 16px;
        }
      }
    }
  }
}

.cards {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  z-index: 2;
  border-radius: 16px;
  overflow: hidden;

  &__title {
    font-size: 14px;
    font-weight: 700;
    color: #373d59;
    margin: 0;
  }
}

.SwitchTab {
  button {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    height: auto;
    border-radius: 15px;
    padding: 0 16px;
    border: none;
  }
}

.link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #8c6ced;
  text-align: center;
  cursor: pointer;
  transition: 300ms ease-in-out;
  margin-top: 10px;

  &:hover {
    transform: scale(1.05);
  }
}
