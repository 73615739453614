@import "../../../global/vars.scss";

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  @include lg {
    flex-direction: row;
  }
}

.contentContainer {
  background-color: $white;
  border-radius: 16px;
  width: 100%;
  position: relative;
}

.left {
  width: 100%;
  padding-bottom: 10%;
  @include lg {
    padding: 33px 60px 10% 23px;
    line-height: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 2;
  }
  @include xl{
    padding: 33px 60px 0 23px;
  }
  p {
    margin: 0;
    order: 1;
    &.title {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin: 0 0 18px;
      @include lg {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
      }
    }
  }
  span {
    display: block;
    color: #a0aec0;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    margin-bottom: 8px;
    order: 1;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #373d59;
    order: 1;
    @include lg {
      font-size: 16px;
    }
  }
}
