@import "../../global/vars.scss";
@import "../../global/interactions.scss";

.container {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  .avatar {
    height: 35px;
    margin-right: 10px;
    position: relative;
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
    .online {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #37e2a1;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    @include lg{
      height: 48px;
      img{
        width: 48px;
        height: 48px;
      }
      .online{
        width: 12px;
        height: 12px;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      &.name{
        font-weight: 600;
        font-size: 16px;
        line-height: 161.19%;
      }
      &.email{
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}

.text {
  margin-top: 16px;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
  }
  margin-bottom: 11px;
}

.attachments {
  display: flex;
  row-gap: 16px;
  column-gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 12px;
  .imgContainer {
    width: 107px;
    height: 91px;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 107px;
      height: 91px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  > span {
    font-weight: 400;
    font-size: 14px;
    color: #a5a5a5;
  }
  .reply {
    :hover {
      cursor: pointer;
    }
    display: flex;
    img {
      margin-right: 4px;
      margin-top: -2px;
      width: 16px;
    }
    span {
      font-weight: 400;
      font-size: 12px;
    }
  }
}



.modalReply{
  max-width: 633px!important;
  .modalWrapper {
    width: 100%;
    max-width: 633px;
  }
  .modalTitleContainer {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    @include md {
      padding: 20px;
    }
    button {
      background: none;
      border: none;
      outline: none;
      width: 14px;
      height: 14px;
      margin: 0;
      padding: 0;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .modalDivider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .modalBodyContainer {
    padding: 32px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include md {
      padding: 32px 20px 20px;
    }
  }
}

.modal {
  width: 100%;
  @include md {
    max-width: 456px !important;
  }
  .modalDivider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .modalBodyContainer {
    padding: 32px 20px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include md {
      padding: 32px 20px 32px;
    }
  }
  .modalWrapper {
    width: 100%;
    @include md {
      min-width: 456px;
    }
  }

  .modalTitleContainer {
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include md {
      padding: 20px;
    }
    button {
      background: none;
      border: none;
      outline: none;
      width: 14px;
      height: 14px;
      margin: 0;
      padding: 0;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    p {
      margin: 0;
      padding: 0;

      &.modalTitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: $darkGrey;
        @include md {
          font-size: 20px;
          line-height: 27px;
        }
      }
    }
  }
}
.deleteModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.deleteModalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.deleteModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 40px;
  @include lg {
    padding: 0 25px 42px;
  }
  & > img {
    width: 45px;
    display: block;
    margin-bottom: 18px;
    @include lg{
      margin-bottom: 22px;
    }
  }
  & > p {
    margin: 0;
    max-width: 250px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
    @include lg {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 22px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
}

.modalDelete {
  max-width: 576px !important;
}
.deleteBtnModal {
  width: 120px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  img {
    margin-right: 10px;
    filter: brightness(5);
  }
  &:hover {
    background-color: #fff;
    color: #f85c56;
    img {
      filter: none;
    }
  }
}
.fileZone {
  width: 100%;
  min-height: 80px;
  position: relative;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238360E6FF' stroke-width='2' stroke-dasharray='20' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: transparent;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.textInput {
  label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 16px !important;
  }
  margin-bottom: 20px !important;
}

.saveBtn {
  align-self: flex-end;
  width: 100%;
  height: 46px;
  margin-top: 24px;
  @include md{
    width: 162px;
  }
}

.replyContainer {
  display: flex;

  &__replyIcon {
    display: flex;
    align-items: center;
    position: relative;

    & > img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      object-position: center;
    }
  }
}

.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 2px solid #c8ccdd;
  padding-left: 12px;
  margin-left: 12px;
}

.up {
  display: flex;
  align-items: flex-start;
  span {
    font-weight: 600;
    font-size: 16px;
    color: #5d606b;
  }
  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.down {
  p {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
    color: #5d606b;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }
}

.avatarWrapper{
  display: flex;
}

.adminBtn {
  display: flex;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
}

.btnGroup {
  order: 2;
  width: 90px;
  display: flex;
  justify-content: space-evenly;
  height: max-content;
  @include xl {
    order: 4;
    width: 90px;
    min-width: 90px;
  }
}
.replyBlock{
  margin-left: 30px;
  margin-top: 20px;
  @include lg{
    margin-left: 51px;
    margin-top: 29px;
  }
  .replyBlock{
    margin-left: 28px;
    margin-top: 20px;
    @include lg{
      margin-left: 52px;
      margin-top: 25px;
    }
  }
}
