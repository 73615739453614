@import "../../global/vars.scss";

.select {
  text-align: left;
  font-weight: 400;
  outline: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    :global {
      .b__control {
        border-color: rgba(0, 0, 0, 0.1);

        &--menu-is-open {
          border-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  & > div {
    min-height: 1px;

    & > div {
      padding: 0 !important;
    }
  }

  :global {
    .b__input-container {
      margin: 0;
      padding: 0;
    }

    .b__single-value {
      margin: 0;
    }

    .b__control {
      min-height: 20px;
      padding: 12px 16px;
      width: 100%;
      line-height: 1;
      border: none;
      outline: none;
      box-shadow: none;
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      .b__value-container {
        padding: 6px 5px 2px 12px;
      }

      &--is-focused {
        border-color: rgba(0, 0, 0, 0.1) !important;
      }

      &--menu-is-open {
        box-shadow: none;
        border-radius: 6px 6px 0 0;
        border-bottom-color: transparent !important;

        .b__indicators {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .b__menu {
      margin: 0;
      border-radius: 0 0 6px 6px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: none;
      overflow: hidden;
      border-top: 1px solid rgba(196, 196, 196, 0.37);

      &-list {
        max-height: 135px;
      }
    }

    .b__option,
    .b__value-container {
      cursor: pointer;
      min-width: 77px;
      padding: 5px 5px 5px 15px;
      background-color: #fff;
      font-size: 14px;
      line-height: 20px;
    }

    .b__option {
      &:hover {
        font-weight: 600;
      }

      &:active {
        font-weight: 500;
        background-color: transparent;
      }
    }

    .b__option--is-selected {
      display: none;
    }

    .b__indicators {
      svg {
        min-width: 11px;
        width: 11px;

        path {
          stroke: #818181;
        }
      }
    }
  }
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
}

.optionButton {
  background: transparent;
  color: #c8ccdd;
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  border: none;
  text-align: left;
  line-height: 19px;
}

.option {
  text-align: left;
  padding: 0 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #373d59;
  margin: 0;
  width: 100%;
  display: flex;
}

.optionContainer {
  display: flex;
  align-items: center;

  img:first-child {
    margin-left: 16px;
  }
}

.selectedOptionContainer {
  display: flex;
  align-items: center;

  img:first-child {
    margin-right: 16px;
  }
}

.optionWrapper {
  padding: 0 !important;
}

.indicatorBackground {
  height: 100%;
  width: 43px !important;
  background: rgba(240, 236, 255, 0.32);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.buttonWrapper {
  margin-top: 14px;
  border-top: 1px solid rgba(196, 196, 196, 0.37);
  display: flex;
  padding: 16px 14px;

  & > img {
    width: 16px;
  }
}

.deleteContainer {
  display: flex;
  align-items: center;

  & > img {
    margin-left: 0 !important;
    height: 24px;
    width: 24px;
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}

.createNew {
  margin-top: 14px;
  margin-bottom: 14px;

  & span {
    &:hover {
      cursor: pointer;
      color: $purple
    }
  }

}

.badge {
  min-height: 0 !important;
  text-align: center;
}

.badgeContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    height: 2px;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background: #e2e8f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #8c6ced;
    cursor: pointer;
  }

  @include md {
    max-width: 355px;
  }
}
