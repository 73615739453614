@import "../../global/vars.scss";
.container {
  padding: 0 !important;
}

.nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @include md {
    padding: 11px 26px;
    border-top: 0.5px solid #e2e8f0;
    border-bottom: 0.5px solid #e2e8f0;
    justify-content: flex-start;
  }
  .date {
    display: flex;
    order: 2;
    margin: 0 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    @include md {
      order: 1;
      margin: 0 24px 0 0;
    }
    img {
      display: none;
      @include md {
        display: block;
        margin-right: 12px;
      }
    }

    p {
      white-space: nowrap;
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .sphere {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: relative;
    border: 1px solid #e2e8f0;
    cursor: pointer;
    &:first-child {
      order: 1;
      @include md {
        order: 2;
        margin-right: 13px;
      }
    }
    &:last-child {
      order: 3;
    }
    @include md {
      width: 32px;
      height: 32px;
    }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.SwitchTab {
  button {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    height: auto;
    border-radius: 15px;
    padding: 0 16px;
    border: none;
  }
}

.chartHeader {
  display: flex;
  padding: 22px 16px 0;
  justify-content: space-between;
  @include md {
    padding: 15px 24px 0;
    justify-content: flex-start;
  }
  .total {
    width: 32%;
    text-align: center;
    @include md {
      text-align: left;
      width: 27%;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #a5a5a5;
      @include md {
        font-weight: 300;
      }
    }
    .sum {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-top: 4px;
    }
  }
}

.chartWrapper {
  overflow-x: scroll;
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    border-top: 1.5px solid #fff;
    border-bottom: 1.5px solid #fff;
    background: #e2e8f0;
  }
  &::-webkit-scrollbar-thumb {
    background: #c8ccdd;
    border-radius: 25px;
    cursor: pointer;
  }
}

.chartBody {
  min-width: 696px;
  overflow-y: hidden;
  * {
    font-family: "Noto Sans" !important;
  }
}

.header {
  padding: 17px 15px 0;
  display: flex;
  flex-direction: column;
  @include md {
    margin-bottom: 0;
    padding: 0;
  }
  .up {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 17px;
    @include md {
      flex-direction: row;
      padding: 20px 24px 0;
      margin-bottom: 20px;
    }
  }
  h4 {
    margin-bottom: 15px !important;
    align-self: center;
    @include md {
      margin-bottom: 0 !important;
    }
  }
}
