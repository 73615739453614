@import "../../../global/vars.scss";


.backBtn {
  @include md {
    margin: 6px 16px 6px 0;
    width: 42px;
  }

  img {
    width: 100%;
  }
}

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  @include lg {
    flex-direction: row;
  }
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  @include md {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 26px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  order: 1;
  align-items: center;
  flex: 1;
  margin-left: 12px;

  h2 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 20px;
    line-height: 150%;
  }

  @include md {
    margin-left: 0;
    flex: none;
    h2 {
      font-size: 32px;
    }
  }
}

.contentContainer {
  background-color: $white;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: relative;
  @include lg {
    padding-bottom: 20px;
  }
  @include xl {
    min-height: 550px;
    padding-bottom: 46px;
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;

  img {
    width: 15px;
    display: block;
    @include sm {
      margin-right: 14px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 3px;
    color: #373d59;
    width: 40px;
    padding: 0;
    height: 36px;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);

    &.edit {
      padding: 4px 18px 4px 15px;
    }

    span {
      display: none;
    }

    @include sm {
      width: 98px;
      span {
        display: inline;
      }
    }
    @include xxl {
      width: 100px;
    }
  }
}

.button {
  order: 2;
  margin-top: 45px;

  img {
    margin-right: 5px;
  }

  @include lg {
    max-width: 255px;
  }
}

.left {
  display: contents;
  width: 100%;
  @include lg {
    padding: 33px 60px 0 23px;
    line-height: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  & .label {
    margin-left: 14px;
  }

  p {
    margin: 0;
    order: 1;

    &.title {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin: 0 0 24px;
      @include lg {
        font-size: 20px;
        margin: 0 0 20px;
      }
    }
  }

  span {
    display: block;
    color: #A0AEC0;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    margin-bottom: 8px;
    order: 1;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #373D59;
    order: 1;
    @include lg {
      font-size: 16px;
    }
  }
}

.right {
  margin-top: 36px;
  order: 1;
  padding-top: 45px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  @include lg {
    height: 100%;
    padding: 0 30px;
    margin: 0;
    border-top: none;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    row-gap: 37px;
    display: flex;
    justify-content: center;
  }
  @include xl {
    padding: 14px 77px 0 47px;
    margin-top: 20px;
  }

  span {
    display: block;
    @include lg {
      margin-bottom: 8px;
    }
  }

  & > div {
    @include lg {
      width: 203px;
    }
  }

  :global {
    .customCheckbox {
      span {
        margin-bottom: 0;
      }
    }
  }
}

.category, .priority {
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include lg {
    flex-direction: column;
    align-items: flex-start !important;
  }

  & .select {
    margin-top: 0 !important;
  }

  & > div {
    width: 100%;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #373D59;

    @include lg {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 10px;
      line-height: 150%;
      color: #A0AEC0;
      text-transform: uppercase;
    }
  }

  &Badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: $white;
    height: 46px;
    padding: 0 16px;
    text-align: center;
    max-width: 170px;
    font-size: 14px;
    line-height: 19px;
    width: fit-content !important;
    cursor: pointer;

    @include xs {
      max-width: 203px;
    }
    @include lg {
      width: auto;
    }
  }
}

.priority > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #10B521;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
  max-width: 170px;
  cursor: pointer;

  @include lg {
    justify-content: unset;
  }

  @include xs {
    max-width: 203px;
  }

  img {
    display: block;
    margin-right: 12px;
    width: 16px;
  }

  p {
    margin: 0;
  }
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include lg {
    flex-direction: column;
    align-items: flex-start !important;
  }

  & > div {
    width: 100%;
    max-width: 170px;

    & > div {
      min-height: 38px;
    }

    @include xs {
      max-width: 203px;
    }
  }

  & > label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
    display: block;

    @include lg {
      margin-bottom: 8px;
      text-align: left;
      font-weight: 700;
      font-size: 10px;
      line-height: 150%;
      color: #A0AEC0;
      text-transform: uppercase;
    }
  }

  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include lg {
    flex-direction: column;
    align-items: flex-start !important;
  }

  & > div {
    width: 100%;
    max-width: 170px;
    padding: 2px 16px 3px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 11px 15px 11px;
    @include xs {
      max-width: 203px;
    }
  }

  label {
    font-size: 14px;
    line-height: 19px;
    color: $darkGrey;
    white-space: nowrap;
    text-align: left;
    display: block;

    @include lg {
      margin-bottom: 8px;
      text-align: left;
      font-weight: 700;
      font-size: 10px;
      line-height: 150%;
      color: #A0AEC0;
      text-transform: uppercase;
    }
  }

  &:not(:first-child) {
    margin-top: 12px;
    @include xl {
      margin-top: 28px;
    }
  }
}

.favorite {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  cursor: pointer;

  &__title {
    font-weight: 400;
    font-size: 14px;
    color: #373D59;
    margin-left: 0.625rem;
  }

  &:hover {
    & svg {
      path {
        fill: #FFDA18;
        stroke: #FFDA18;
      }
    }
  }

  &__iconStar {
    cursor: pointer;
    transition: 300ms ease-in-out;

    & svg {
      transition: 500ms ease-in-out;

      path {
        transition: 500ms ease-in-out;
        fill: #fff;
        stroke: #FFDA18;
        stroke-width: 1;
      }
    }

    &__active {
      & svg {
        path {
          fill: #FFDA18;
          stroke: #FFDA18;
        }
      }
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}


.modalDelete {
  max-width: 576px !important;
}

.deleteBtnModal {
  width: 120px;
  outline: none;
  background-color: #f85c56;
  border: 1px solid #f85c56;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #f85c56;

    img {
      filter: none;
    }
  }
}

.deleteModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.deleteModalTitleContainer {
  display: flex;
  padding: 14px 8px;
  justify-content: flex-end;
  @include lg {
    padding: 20px 13px;
  }
}

.deleteModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 40px;
  @include lg {
    padding: 0 25px 42px;
  }

  & > img {
    width: 45px;
    display: block;
    margin-bottom: 18px;
    @include lg {
      margin-bottom: 22px;
    }
  }

  & > p {
    margin: 0;
    max-width: 250px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
    @include lg {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 22px;
    }
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #a5a5a5;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
}

.btnContainer {
  display: flex;
  column-gap: 26px;
}

.noBtnModal {
  width: 120px;
  outline: none;
  background-color: #8C6CED;
  border: 1px solid #8C6CED;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin-right: 10px;
    filter: brightness(5);
  }

  &:hover {
    background-color: #fff;
    color: #8C6CED;

    img {
      filter: none;
    }
  }
}

.topLeft {
  display: flex;
  align-items: center;
}

.field {
  border: 1px solid transparent !important;
  transition: 300ms ease-in-out;

  &__title {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
  }

  &__focus {
    border: 1px solid $primary !important;
  }

  &__error {
    border: 1px solid $error !important;
  }
}
