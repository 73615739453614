@import "../../global/vars.scss";
@import "../../global/interactions.scss";

.createFolderModal {
  max-width: 450px !important;
  border-radius: 6px;
  background: white;
}

.container {
  position: relative;

  &__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1px;
      background: #f0ecff;
    }

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }
    & .title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }

    & .closeIcon {
      width: 16px;
      height: 16px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 1rem;

    &__flex {
      display: flex;
      flex-direction: column;

      &__reverse {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__colors {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 1rem;
      max-height: 131px;
      overflow-y: auto;

      &__edit {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      & .title {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #373d59;
      }

      & .sketchPicker {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 1rem;
        border-radius: 6px;
        background: #fff;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        z-index: 10;

        & button {
          margin-top: 1rem;
          background: none;
          border: none;
          outline: none;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 19px;
          font-weight: 700;
          color: #373d59;
        }
      }

      & .sphere {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &.add {
          border: 1px solid #acacac;
          box-sizing: border-box;
        }
      }
    }
  }
}

.input {
  position: relative;
  width: 100%;

  &__container {
    position: relative;
  }

  &__label {
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #373d59;
    margin-bottom: 6px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    cursor: pointer;
    transition: 300ms ease-in-out;
    z-index: 10;

    &__open {
      transform: translateY(-50%) rotate(180deg);
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__select {
    width: 100%;
    height: 46px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 0 1rem;
    font-size: 16px;
    line-height: 22px;
    color: #4f4f4f;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #a5a5a5;
    }

    &:focus {
      outline: none;
      border: 1px solid #7e5ae1;
    }
  }

  &__options {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border-top: unset;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transform: translateY(-46px);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 1;
    padding: 0.5rem 0;
    opacity: 0;
    pointer-events: none;
    transition: 300ms ease-in-out;

    &__open {
      opacity: 1;
      transform: translateY(-4px);
      pointer-events: all;
    }

    &__option {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #373d59;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: 150ms ease-in-out;

      &:hover {
        background: rgba(241, 241, 241, 0.81);
      }
    }
  }
}

.submitBtn {
  background: linear-gradient(180deg, #9b7efa 0%, #7e5ae1 100%);
  margin: 32px 0 0 auto;
  transition: 300ms ease-in-out;

  &:hover {
    background: linear-gradient(150deg, #9b7efa 0%, #7e5ae1 100%);
  }
}
