@import "../../../global/vars.scss";

.container {
  display: flex;
  justify-content: center;
  padding: 0 0 17px !important;
  // @include xxl {
  //   width: 525px !important;
  //   max-width: 525px;
  // }
}

.header {
  padding: 16px 14px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  z-index: 9;
  @include md {
    padding: 20px 20px 0 23px;
  }

  .up {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    @include lg {
      margin-bottom: 19px;
    }
  }

  .down {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
  }

  .selectBadge {
    color: black;
    position: relative;
    display: flex;
    align-items: center;

    &Month {
      order: 3;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      border: none;
      width: 130px;
      justify-content: center;
      @include md {
        order: 1;
        margin-bottom: 0;
      }
    }

    &Year {
      order: 1;
      margin-bottom: 10px;
      width: 90px;
      @include md {
        order: 2;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
      }
    }
  }
}

.SwitchTabContainer {
  order: 2;
  @include lg {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .SwitchTab {
    button {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      height: auto;
      padding: 0 13px;
      border: 0;
      border-radius: 15px;
      @include xs {
        padding: 0 19px;
      }
      @include xl {
        padding: 0 22px;
      }
    }
  }
}

.modalButton {
  margin-top: 47px;
  align-self: flex-end;
  width: 100%;
  @include md {
    width: 222px;
  }
}

.checkBox {
  span {
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    color: #c7c8cc !important;
  }
}

.socialContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1.5px);
    z-index: 1;
  }

  & .social {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    button {
      width: 100%;
      max-width: 270px;
      padding: 12px 20px;
      justify-content: center;
    }

    p {
      margin: 0;
      font-weight: 700;
      font-size: 13px;
      text-align: center;
      margin-bottom: 13.5px;
      @include lg {
        font-size: 20px;
      }
    }
  }
}
