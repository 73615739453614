@import '../global/vars.scss';

.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
  }

  @include md {
    h2 {
      font-size: 32px;
    }
  }

  button {
    @include md {
      min-width: 222px;
    }
  }
}

.swiperSlide {
  display: flex;
  justify-content: center;
}

.container {
  height: 100%;
}

.mobileCard {
  &:hover {
    cursor: pointer;
  }
}

.nothingHere {
  width: 100%;
  height: 100%;
  max-width: 242px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include md {
    max-width: 307px;
  }

  img {
    width: 100%;
    display: block;
    margin-bottom: 24px;
    @include md {
      margin-bottom: 47px;
    }
  }

  &Border {
    height: 100%;
  }
}

.nothingHereDescription {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    @include md {
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 16px;
    }
  }

  span {
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 19px;
    @include md {
      font-size: 16px;
      margin-bottom: 8px;
      line-height: 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.helperText {
      color: $darkGrey;
      font-weight: 100;
    }
  }

  a.helperLink {
    color: $purple;
    font-weight: 600;
  }

  button {
    margin-top: 86px;
    @include md {
      display: none;
    }
  }
}

.projectsContainer {
  height: 100%;
  display: flex;
  flex: 1;
  @include sm {
    flex: none;
    margin: -10px;
  }
  @include xl {
    grid-gap: 20px;
  }
}

.btnPlus {
  @include sm {
    display: none !important;
  }
}
