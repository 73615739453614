@import "../../global/vars.scss";

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.datePicker {
  outline: none !important;
  border: none;
  width: 100%;
  padding: 0;
  margin: 0;

  input {
    background-color: red;
  }
}

.datePickerWrapper {
  position: relative;
  width: 100%;
  max-width: 170px;
  padding: 11px 15px 11px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  text-align: left;

  &.error {
    border-color: #fb5b55;
  }

  img {
    position: absolute;
    top: 50%;
    // z-index: 1;
    transform: translateY(-50%);
    right: 13px;
  }

  @include xs {
    max-width: 203px;
  }
}

.errorLabel {
  color: #fb5b55 !important;
}

.rightBackground {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 45px;
  background: rgba(240, 236, 255, 0.32);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.validationHelper {
  font-weight: 400;
  text-align: right;
  display: block;
  font-size: 12px;
  color: red;
  padding-top: 8px;
}
