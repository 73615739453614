.suggestionBar{
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 327px;
}
.mainText, .helperText {
    text-align: center;
    color: #fff;
}
.mainText{
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
}
.helperText{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 24px;
}

