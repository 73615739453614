@import "../../global/vars";

.container {
  $progress-bar-stroke-width: 5;
  display: flex;
  align-items: center;

  & .circle {
    position: relative;
    width: 100px;

    @media screen and (max-width: $xl) {
      width: 80px;
    }
  }

  & svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
  }

  & .background {
    fill: none;
    stroke: #E2E8F0;
    stroke-width: $progress-bar-stroke-width;
  }

  & .progress {
    fill: none;
    stroke: var(--color);
    stroke-dasharray: var(--progress) 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    stroke-width: $progress-bar-stroke-width;
    transition: stroke-dashoffset 1s ease-in-out;
  }

  & .progressText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;

    @media screen and (max-width: $xl) {
      font-size: 16px;
    }
  }
}

.data {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  gap: 5px;

  @media screen and (max-width: $md) {
    margin-left: 0;
    margin-top: 0.75rem;
    gap: 4px;
  }

  & p {
    margin: 0;
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #A5A5A5;

    @media screen and (max-width: $xl) {
      font-size: 12px;
      line-height: 16.34px;
    }
  }

  &__value {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;

    @media screen and (max-width: $xl) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &__percent {
    display: flex;
    align-items: center;

    @media screen and (max-width: $md) {
      justify-content: center;
    }

    & > p {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      margin-left: 6px;
      color: #1AD598;

      @media screen and (max-width: $xl) {
        font-size: 12px;
        line-height: 16.34px;
      }
    }
  }
}
