@import "../../global/vars.scss";
@import "../../global/interactions.scss";

.top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  @include md {
    align-items: center;
    margin-bottom: 30px;
  }
  @include xxl {
    padding-right: 20px;
    margin-bottom: 24px;
  }
}

.smallTitle {
  text-transform: capitalize;
  margin: -24px -16px 24px;
  border-bottom: 1px solid #ededed;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #373d59;
  @include md {
    display: none;
  }
}

.backBtn {
  width: 30px;
  margin-right: 6px;
  @include md {
    margin-right: 12px;
  }

  @include animateBack;

  img {
    width: 100%;
  }
}

.title {
  display: flex;
  order: 1;
  align-items: center;
  flex: 1;

  h2 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 20px;
    line-height: 150%;
  }

  @include md {
    margin-left: 0;
    flex: none;
    min-width: 210px;
    h2 {
      font-size: 32px;
      padding-right: 40px;
    }
  }
}

.contentContainer {
  background-color: $white;
  border-radius: 16px;
  width: 100%;
  position: relative;
  min-height: 50vh;
  @include xl {
    display: flex;
    min-height: 55vh;
    padding-bottom: 46px;
  }
}

.switchTab {
  order: 3;
  margin-bottom: 20px;
  height: auto;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 11px;

  img {
    @include md {
      margin-right: 5px;
    }
  }

  @include lg {
    gap: 0;
    background: #f5f4f8;
    border-radius: 12px;
  }
  @include xl {
    flex: 1;
    max-width: 539px;
    order: 2;
    margin: 0 10px;
  }
  @include xxl {
    margin: 0 61px 0 10px;
    max-width: 596px;
  }

  @media (min-width: $lg) and (max-width: $xl) {
    max-height: 36px;
    max-width: 90%;
    margin: 20px auto 0 auto;
  }

  button {
    color: $white;
    width: calc(25% - 5.5px);
    height: 63px;
    padding: 0;
    border-radius: 12px;

    &[class^="button_transparent"] {
      background: rgba(255, 255, 255, 0.39);
      border: 2px solid #fff;
      color: #373d59;
    }

    @include lg {
      width: 25%;
      min-width: 110px;
      height: 36px;
      min-width: 100px;
      &[class^="button_transparent"] {
        background: none;
        border: 2px solid transparent;
      }
    }

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      overflow: hidden;
      max-width: 45px;
      @include xxl {
        max-width: 100%;
        font-weight: 500;
      }
    }

    & > div {
      flex-direction: column;
      @include md {
        flex-direction: row;
      }
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  order: 2;
  width: 88px;
  height: 36px;
  @include md {
    column-gap: 13px;
    width: 93px;
  }
  @include lg {
    width: 209px;
  }
  @include xxl {
    order: 3;
    column-gap: 15px;
    width: 215px;
  }

  img {
    width: 20px;
    @include lg {
      margin-right: 14px;
      width: 15px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 3px;
    color: #373d59;
    width: 40px;
    padding: 0;
    height: 36px;

    &.delete:hover {
      color: $error;
      background-color: transparent;
      & img {
        filter: hue-rotate(0deg);
      }
    }

    span {
      display: none;
    }

    &:hover {
      img {
        filter: brightness(4);
      }
    }

    @include lg {
      width: 98px;
      span {
        display: inline;
      }
    }
    @include xxl {
      width: 100px;
    }
  }
}
